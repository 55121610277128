import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Tooltip } from 'reactstrap';
import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { unitsReplenishStock, costReplenishStock, costStockActualSupplies } from '../utils/functions';
import InputControlled from './InputControlled';
import { getPayUser } from '../redux/action';
import { NumericInput } from './NumericInput';

export default function FormSupplies({ title, state, errors, setState, back, vendors, setAddVendors, addVendors, idVendor, onSubmit, setModal, disabled }) {
    const [help, setHelp] = React.useState('');
    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();

    const userIsPayment = useSelector(stee => stee?.userPremiumPay)

    useEffect(() => {
        dispatch(getPayUser())
    }, [dispatch])

    useEffect(() => {
        let cost_unit = parseFloat(state.cost_unit)
        let square_meter = state.square_meter ? parseFloat(state.square_meter) : 1
        let unit_measurement_count = parseFloat(state.unit_measurement_count)

        let cost_per_unit_of_use = state.unit_measurement === 'pk' ? (cost_unit / unit_measurement_count).toFixed(2) : (cost_unit / square_meter).toFixed(2)
        setState({...state, cost_per_unit_of_use: cost_per_unit_of_use})
    }, [state.unit_measurement_count, state.unit_measurement, state.cost_unit, state.current_stock_in_units, state.cost_unit, state.square_meter])

    return (
        <>
            <div className={history.location.pathname.includes('product') ? null : "card mb-3"}>
                <>
                    {
                        history.location.pathname.includes('product') ? null : (
                            <div className="card-header mt-1">
                                <div className="card-title">
                                        <h2>
                                            {title}
                                        </h2>
                                </div>
                            </div>
                        )
                    }
                    <div className="card-body pt-1">
                        <form>
                            <Row>
                                <Col md="12">
                                    <InputControlled
                                        classContainer={'form-group'}
                                        classLabel={'form-label fs-6'}
                                        textLabel={'Elemento'}
                                        typeBtn={'text'}
                                        nameBtn={'name'}
                                        styleBtn={errors.name.bol ?
                                            "form-control p-form  br-btn-t error-input" :
                                            "form-control p-form  br-btn-t"
                                        }
                                        valueBtn={state.name}
                                        setValue={setState}
                                        state={state}
                                        read={history.location.pathname.includes('view') ?
                                            true : undefined
                                        }
                                        classError={errors.name.bol ?
                                            "invalid-input" :
                                            "valid-input"
                                        }
                                        textError={errors.name.msg}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                {
                                    state.unit_measurement === "pk" ? (
                                        <>
                                            <Col md="3">
                                                <div className="form-group">
                                                    <label className="form-label fs-6">Unidad de compra</label>
                                                    {/* <HelpOutlineIcon
                                                        disabled
                                                        style={{ marginLeft: "1rem", fontSize: "large" }}
                                                        onMouseEnter={() => {
                                                            setHelp('unit_measurement')
                                                        }}
                                                        onMouseLeave={() => {
                                                            setHelp('')
                                                        }}
                                                        id="unit_measurement"
                                                    />
                                                    <Tooltip placement="right" isOpen={help === "unit_measurement"} target="unit_measurement">
                                                        Acá deberás ingresar la unidad de compra. Por ejemplo, el kilo o el metro. Si es mano de obra ingresalo por hora, y si es packaging ingresá el costo de la unidad de cada elemento. Estos últimos dos están abajo de todo.
                                                    </Tooltip> */}
                                                    {
                                                        history.location.pathname.includes('view') ? (
                                                            <input
                                                                type={'text'}
                                                                className="form-control p-form  br-btn-t btn-disabled"
                                                                value={state.unit_measurement === "kg" ?
                                                                    "Kilogramo" : state.unit_measurement === "m" ?
                                                                        "Metros" : state.unit_measurement === "cm²" ?
                                                                            "Metros cuadrados" : state.unit_measurement === "l" ?
                                                                                "Litros" : state.unit_measurement === "un" ?
                                                                                    "Unidad" : null
                                                                }
                                                                readOnly
                                                            />
                                                        ) : (
                                                            <select
                                                                onChange={
                                                                    (e) => setState({
                                                                        ...state,
                                                                        unit_measurement: e.target.value
                                                                    })}
                                                                value={state.unit_measurement}
                                                                className={errors.unit_measurement.bol ? "custom-select  error-input" : "custom-select "}
                                                            >
                                                                <option default value={""}>Seleccione una unidad de medida</option>
                                                                <option value="kg">Kilogramo</option>
                                                                <option value="m">Metros</option>
                                                                <option value="cm²">Metros cuadrados</option>
                                                                <option value="l">Litros</option>
                                                                <option value="ml">Mililitros</option>
                                                                <option value="pk">Paquete</option>
                                                                <option value="un">Unidad</option>
                                                            </select>
                                                        )
                                                    }
                                                    <div className={errors.unit_measurement.bol ? "invalid-input" : "valid-input"}>
                                                        {errors.unit_measurement.msg}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="form-group margin-bottom-0" >
                                                    <label className="form-label fs-6">Cantidad por paquete</label>
                                                    <div className="d-flex">
                                                        {
                                                            history.location.pathname.includes('supplies/view') ? (
                                                                <input
                                                                    type={'number'}
                                                                    name={'unit_measurement_count'}
                                                                    className={"btn-disabled form-control p-form  br-btn-t"}
                                                                    value={state.unit_measurement_count}
                                                                    readOnly
                                                                />
                                                            ) : (
                                                                <>
                                                                    <NumericInput
                                                                        value={state.unit_measurement_count}
                                                                        name={'unit_measurement_count'}
                                                                        className={"form-control p-form  br-btn-t"}
                                                                        decimalScale={0}
                                                                        onValueChange={e => {
                                                                            setState({
                                                                                ...state,
                                                                                unit_measurement_count: e.value,
                                                                            })
                                                                        }}
                                                                    />
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </Col>
                                        </>
                                    ) : (
                                        <Col md={!userIsPayment?.plan?.is_free ? 6 : 12}>
                                            <div className="form-group">
                                                <label className="form-label fs-6">Unidad de compra</label>
                                                {/* <HelpOutlineIcon
                                                    disabled
                                                    style={{ marginLeft: "1rem", fontSize: "large" }}
                                                    onMouseEnter={() => {
                                                        setHelp('unit_measurement')
                                                    }}
                                                    onMouseLeave={() => {
                                                        setHelp('')
                                                    }}
                                                    id="unit_measurement"
                                                />
                                                <Tooltip placement="right" isOpen={help === "unit_measurement"} target="unit_measurement">
                                                    Acá deberás ingresar la unidad de compra. Por ejemplo, el kilo o el metro. Si es mano de obra ingresalo por hora, y si es packaging ingresá el costo de la unidad de cada elemento. Estos últimos dos están abajo de todo.
                                                </Tooltip> */}
                                                {
                                                    history.location.pathname.includes('view') ? (
                                                        <input
                                                            type={'text'}
                                                            className="form-control p-form  br-btn-t btn-disabled"
                                                            value={state.unit_measurement === "kg" ?
                                                                "Kilogramo" : state.unit_measurement === "m" ?
                                                                    "Metros" : state.unit_measurement === "m" && state.square_meter ?
                                                                        "Metros cuadrados" : state.unit_measurement === "l" ?
                                                                            "Litros" : state.unit_measurement === "un" ?
                                                                                "Unidad" : null
                                                            }
                                                            readOnly
                                                        />
                                                    ) : (
                                                        <select
                                                            onChange={
                                                                (e) => setState({
                                                                    ...state,
                                                                    unit_measurement: e.target.value
                                                                })}
                                                            value={state.unit_measurement}
                                                            className={errors.unit_measurement.bol ? "custom-select  error-input" : "custom-select "}
                                                        >
                                                            <option default value={""}>Seleccione una unidad de medida</option>
                                                            <option value="kg">Kilogramo</option>
                                                            <option value="m">Metros</option>
                                                            <option value="cm²">Metros cuadrados</option>
                                                            <option value="l">Litros</option>
                                                            <option value="ml">Mililitros</option>
                                                            <option value="pk">Paquete</option>
                                                            <option value="un">Unidad</option>
                                                        </select>
                                                    )
                                                }
                                                <div className={errors.unit_measurement.bol ? "invalid-input" : "valid-input"}>
                                                    {errors.unit_measurement.msg}
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                }

                                {!userIsPayment?.plan?.is_free ?
                                <Col md="6">
                                    <div className="form-group margin-bottom-0" >
                                        <label className="form-label fs-6">Proveedor</label>
                                        <HelpOutlineIcon
                                            disabled
                                            style={{ marginLeft: "1rem", fontSize: "large" }}
                                            onMouseEnter={() => {
                                                setHelp('provider')
                                            }}
                                            onMouseLeave={() => {
                                                setHelp('')
                                            }}
                                            id="provider"
                                        />
                                        <Tooltip placement="right" isOpen={help === "provider"} target="provider">
                                            En este campo deberás elegir un proveedor previamente cargado o agregar uno nuevo desde el button "+ Agregar proveedor"
                                        </Tooltip>
                                        {
                                            history.location.pathname.includes('view') ? (
                                                <input
                                                    type={'text'}
                                                    className="form-control p-form  br-btn-t btn-disabled"
                                                    value={state.provider}
                                                    readOnly
                                                />
                                            ) : (
                                                <>
                                                    <select
                                                        onChange={(e) => setState({
                                                            ...state,
                                                            provider: e.target.value
                                                        })}
                                                        value={state.provider ? state.provider : undefined}
                                                        className={errors.provider.bol ?
                                                            "custom-select  error-input" :
                                                            "custom-select "
                                                        }
                                                    >
                                                        <option default value="">Seleccione un proveedor</option>
                                                        {
                                                            vendors.results ? vendors.results.map(el => {
                                                                return (
                                                                    idVendor && el.id === idVendor ? (
                                                                        <option key={el.id} default value={el.id}>{el.name}</option>
                                                                    ) : (
                                                                        <option key={el.id} value={el.id}>{el.name}</option>
                                                                    )
                                                                )
                                                            }) : null
                                                        }
                                                    </select>
                                                    <Row>
                                                        <Col sm="12">
                                                            <div
                                                                className={errors.provider.bol ?
                                                                    "invalid-input" :
                                                                    "valid-input"
                                                                }
                                                            >
                                                                {errors.provider.msg}
                                                            </div>
                                                        </Col>
                                                        <Col sm="12">
                                                            <button
                                                                type='button'
                                                                className="linkModal color-primary background-transparent border-none"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setAddVendors(!addVendors)
                                                                }}
                                                            >
                                                                + Agregar proveedor
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )
                                        }
                                    </div>
                                </Col>
                                : null}
                            </Row>
                            {/* <hr /> */}
                            {
                                state.unit_measurement === "cm²" ? (
                                    <>
                                        <Row>
                                            <Col sm="12" md="4">
                                                <div className="form-group margin-bottom-0" >
                                                    <label className="form-label fs-6">Largo del elemento (cm)</label>
                                                    <div className="d-flex">
                                                        {
                                                            history.location.pathname.includes('supplies/view') ? (
                                                                <>
                                                                    <NumericInput
                                                                        value={state.element_height}
                                                                        name={'element_height'}
                                                                        className={"btn-disabled form-control p-form  br-btn-t"}
                                                                        readOnly={true}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <NumericInput
                                                                        value={state.element_height}
                                                                        name={'element_height'}
                                                                        className={errors.element_height.bol ?
                                                                            "form-control p-form  br-btn-t error-input" :
                                                                            "form-control p-form  br-btn-t"
                                                                        }
                                                                        onValueChange={e => {
                                                                            setState({
                                                                                ...state,
                                                                                element_height: e.value,
                                                                                square_meter: e.value * state.element_width / 1000
                                                                            })
                                                                        }}
                                                                    />
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col sm="12" md="4">
                                                <div className="form-group margin-bottom-0" >
                                                    <label className="form-label fs-6">Ancho del elemento (cm)</label>
                                                    <div className="d-flex">
                                                        {
                                                            history.location.pathname.includes('supplies/view') ? (
                                                                <>
                                                                    <NumericInput
                                                                        value={state.element_width}
                                                                        name={'element_width'}
                                                                        className={"btn-disabled form-control p-form  br-btn-t"}
                                                                        readOnly={true}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <NumericInput
                                                                        value={state.element_width}
                                                                        name={'element_width'}
                                                                        className={errors.element_width.bol ?
                                                                            "form-control p-form  br-btn-t error-input" :
                                                                            "form-control p-form  br-btn-t"
                                                                        }
                                                                        onValueChange={e => {
                                                                            setState({
                                                                                ...state,
                                                                                element_width: e.value,
                                                                                square_meter: e.value * state.element_height / 1000
                                                                            })
                                                                        }}
                                                                    />
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col sm="12" md="4">
                                                <div className="form-group margin-bottom-0" >
                                                    <label className="form-label fs-6">Metros cuadrados totales</label>
                                                    <HelpOutlineIcon
                                                        disabled
                                                        style={{ marginLeft: "1rem", fontSize: "large" }}
                                                        onMouseEnter={() => {
                                                            setHelp('square_meter')
                                                        }}
                                                        onMouseLeave={() => {
                                                            setHelp('')
                                                        }}
                                                        id="square_meter"
                                                    />
                                                    <Tooltip placement="right" isOpen={help === "square_meter"} target="square_meter">
                                                        El sistema hará los cálculos de forma automática con los dos campos anteriores.
                                                    </Tooltip>
                                                    <div className="d-flex">
                                                        {
                                                            history.location.pathname.includes('supplies/view') ? (
                                                                <>
                                                                    <NumericInput
                                                                        value={state.square_meter}
                                                                        name={'square_meter'}
                                                                        className={"btn-disabled form-control p-form  br-btn-t"}
                                                                        readOnly={true}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <NumericInput
                                                                        value={state.square_meter}
                                                                        name={'square_meter'}
                                                                        className={errors.square_meter.bol ?
                                                                            "form-control p-form  br-btn-t error-input" :
                                                                            "form-control p-form  br-btn-t"
                                                                        }
                                                                        readOnly={true}
                                                                    />
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <br />
                                    </>
                                ) : null
                            }
                            <Row>
                                <Col md="12">
                                    <Row>
                                        <Col sm="12" md={state.unit_measurement !== 'pk' && state.unit_measurement !== 'cm²' ? 6 : 3}>
                                            <div className="form-group">
                                                <div className=''>
                                                    <label className="form-label fs-6">Costo por unidad</label>
                                                    <HelpOutlineIcon
                                                        disabled
                                                        style={{ marginLeft: "1rem", fontSize: "large" }}
                                                        onMouseEnter={() => {
                                                            setHelp('cost_unit')
                                                        }}
                                                        onMouseLeave={() => {
                                                            setHelp('')
                                                        }}
                                                        id="cost_unit"
                                                    />
                                                </div>
                                                <Tooltip placement="right" isOpen={help === "cost_unit"} target="cost_unit">
                                                    Aca debés completar con el importe que abonás por cada unidad de compra. Por ejemplo: kilo o metro.
                                                </Tooltip>
                                                {
                                                    history.location.pathname.includes('supplies/view') ? (
                                                        <>
                                                            <NumericInput
                                                                value={state.cost_unit}
                                                                name={'cost_unit'}
                                                                className={"btn-disabled form-control p-form  br-btn-t"}
                                                                readOnly={true}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <NumericInput
                                                                value={state.cost_unit}
                                                                name={'cost_unit'}
                                                                className={errors.cost_unit.bol ?
                                                                    "form-control p-form  br-btn-t error-input" :
                                                                    "form-control p-form  br-btn-t"
                                                                }
                                                                onValueChange={e => {
                                                                    setState({
                                                                        ...state,
                                                                        cost_unit: e.value,
                                                                        stock_replenish_in_cost: costReplenishStock({
                                                                            ...state,
                                                                            cost_unit: e.value,
                                                                        }),
                                                                        current_stock_in_cost: costStockActualSupplies({
                                                                            ...state,
                                                                            cost_unit: e.value
                                                                        })
                                                                    })
                                                                }}
                                                            />
                                                            <div className={errors.cost_unit.bol ? "invalid-input" : "valid-input"}>
                                                                {errors.cost_unit.msg}
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </Col>

                                        <Col sm="12" md="6">
                                            <div className="form-group">
                                                <label className="form-label fs-6">Stock actual en unidades</label>
                                                <HelpOutlineIcon
                                                    disabled
                                                    style={{ marginLeft: "1rem", fontSize: "large" }}
                                                    onMouseEnter={() => {
                                                        setHelp('current_stock_in_units')
                                                    }}
                                                    onMouseLeave={() => {
                                                        setHelp('')
                                                    }}
                                                    id="current_stock_in_units"
                                                />
                                                <Tooltip placement="right" isOpen={help === "current_stock_in_units"} target="current_stock_in_units">
                                                    La cantidad de unidades que tenés en stock actualmente
                                                </Tooltip>
                                                {
                                                    history.location.pathname.includes('supplies/view') ? (
                                                        <>
                                                            <NumericInput
                                                                value={state.current_stock_in_units}
                                                                name={'current_stock_in_units'}
                                                                className={"btn-disabled form-control p-form  br-btn-t"}
                                                                readOnly={true}
                                                                decimalScale={0}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <NumericInput
                                                                value={state.current_stock_in_units}
                                                                name={'current_stock_in_units'}
                                                                className={errors.current_stock_in_units.bol ?
                                                                    "form-control p-form  br-btn-t error-input" :
                                                                    "form-control p-form  br-btn-t"
                                                                }
                                                                onValueChange={e => {
                                                                    setState({
                                                                        ...state,
                                                                        current_stock_in_units: e.value,
                                                                        stock_replenish: unitsReplenishStock({
                                                                            ...state,
                                                                            current_stock_in_units: e.value,
                                                                        }),
                                                                        stock_replenish_in_cost: costReplenishStock({
                                                                            ...state,
                                                                            current_stock_in_units: e.value,
                                                                        }),
                                                                        current_stock_in_cost: costStockActualSupplies({
                                                                            ...state,
                                                                            current_stock_in_units: e.value
                                                                        })
                                                                    })
                                                                }}
                                                                decimalScale={0}
                                                            />
                                                            <div className={errors.current_stock_in_units.bol ? "invalid-input" : "valid-input"}>
                                                                {errors.current_stock_in_units.msg}
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </Col>

                                        {state.unit_measurement === 'pk' || state.unit_measurement === 'cm²' ?
                                        <Col sm="12" md={3}>
                                            <div className="form-group">
                                                {/* <label className="form-label fs-6">Costo por unidad de uso</label> */}
                                                <label className="form-label fs-6">{
                                                    state.unit_measurement === 'pk'
                                                    ?
                                                        "Costo por unidad de uso"
                                                    :
                                                        "Valor del metro cuadrado completo"
                                                }</label>
                                                <NumericInput
                                                    value={state.cost_per_unit_of_use}
                                                    name={'cost_per_unit_of_use'}
                                                    className={errors.cost_per_unit_of_use?.bol ?
                                                        "form-control p-form  br-btn-t error-input" :
                                                        "form-control p-form  br-btn-t"
                                                    }
                                                    readOnly={true}
                                                />
                                                <small className="form-text text-muted" style={{fontSize: 12}}>Este valor se calcula automáticamente</small>
                                                <div className={errors.cost_per_unit_of_use?.bol ? "invalid-input" : "valid-input"}>
                                                    {errors.cost_per_unit_of_use?.msg}
                                                </div>
                                            </div>
                                        </Col>
                                        : null}
                                    </Row>
                                    <Row>

                                    {!userIsPayment?.plan?.is_free ?
                                    <Col sm="12" md="6">
                                            <div className="form-group">
                                                <label className="form-label fs-6">Stock ideal en unidades</label>
                                                <HelpOutlineIcon
                                                    disabled
                                                    style={{ marginLeft: "1rem", fontSize: "large" }}
                                                    onMouseEnter={() => {
                                                        setHelp('ideal_stock_in_units')
                                                    }}
                                                    onMouseLeave={() => {
                                                        setHelp('')
                                                    }}
                                                    id="ideal_stock_in_units"
                                                />
                                                <Tooltip placement="right" isOpen={help === "ideal_stock_in_units"} target="ideal_stock_in_units">
                                                    Esta es la cantidad de unidades que tu proyecto debería tener de cada producto
                                                </Tooltip>
                                                {
                                                    history.location.pathname.includes('view') ? (
                                                        <>
                                                            <NumericInput
                                                                value={state.ideal_stock_in_units}
                                                                name={'ideal_stock_in_units'}
                                                                className={"btn-disabled form-control p-form  br-btn-t"}
                                                                decimalScale={0}
                                                                readOnly={true}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <NumericInput
                                                                value={state.ideal_stock_in_units}
                                                                name={'ideal_stock_in_units'}
                                                                className={errors.ideal_stock_in_units.bol ?
                                                                    "form-control p-form  br-btn-t error-input" :
                                                                    "form-control p-form  br-btn-t"
                                                                }
                                                                decimalScale={0}
                                                                onValueChange={e => {
                                                                    setState({
                                                                        ...state,
                                                                        ideal_stock_in_units: e.value,
                                                                        stock_replenish: unitsReplenishStock({
                                                                            ...state,
                                                                            ideal_stock_in_units: e.value,
                                                                        }),
                                                                        stock_replenish_in_cost: costReplenishStock({
                                                                            ...state,
                                                                            ideal_stock_in_units: e.value,
                                                                        })
                                                                    })
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                }
                                                <div className={errors.ideal_stock_in_units.bol ? "invalid-input" : "valid-input"}>
                                                    {errors.ideal_stock_in_units.msg}
                                                </div>
                                            </div>
                                        </Col>
                                    : null}

                                        <Col sm="12" md="6">
                                            <div className="form-group">
                                                <label className="form-label fs-6">Stock actual en costo</label>
                                                <HelpOutlineIcon
                                                    disabled
                                                    style={{ marginLeft: "1rem", fontSize: "large" }}
                                                    onMouseEnter={() => {
                                                        setHelp('current_stock_in_cost')
                                                    }}
                                                    onMouseLeave={() => {
                                                        setHelp('')
                                                    }}
                                                    id="current_stock_in_cost"
                                                />
                                                <Tooltip placement="right" isOpen={help === "current_stock_in_cost"} target="current_stock_in_cost">
                                                    Este es el valor en dinero de las unidades que tenés en stock, utilizando de base el precio de costo de tu producto.
                                                </Tooltip>
                                                {
                                                    history.location.pathname.includes('view') ? (
                                                        <>
                                                            <NumericInput
                                                                value={state.current_stock_in_cost}
                                                                name={'current_stock_in_cost'}
                                                                className={"btn-disabled form-control p-form  br-btn-t"}
                                                                readOnly={true}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <NumericInput
                                                                value={state.current_stock_in_cost}
                                                                name={'current_stock_in_cost'}
                                                                className={"form-control p-form  br-btn-t"}
                                                                readOnly={true}
                                                            />

                                                            <small className="form-text text-muted" style={{fontSize: 12}}>Este valor se calcula automáticamente</small>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </Col>
                                        
                                        {!userIsPayment?.plan?.is_free ?
                                        <Col sm="12" md="6">
                                            <div className="form-group">
                                                <label className="form-label fs-6">Stock a reponer en unidades</label>
                                                <HelpOutlineIcon
                                                    disabled
                                                    style={{ marginLeft: "1rem", fontSize: "large" }}
                                                    onMouseEnter={() => {
                                                        setHelp('stock_replenish')
                                                    }}
                                                    onMouseLeave={() => {
                                                        setHelp('')
                                                    }}
                                                    id="stock_replenish"
                                                />
                                                <Tooltip placement="right" isOpen={help === "stock_replenish"} target="stock_replenish">
                                                    Esta es la cantidad de unidades que deberías encargar para tener tu stock ideal teniendo en cuenta las unidades que tenés actualmente
                                                </Tooltip>
                                                {
                                                    history.location.pathname.includes('view') ? (
                                                        <>
                                                            <NumericInput
                                                                value={state.stock_replenish}
                                                                name={'stock_replenish'}
                                                                className={"btn-disabled form-control p-form  br-btn-t"}
                                                                readOnly={true}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <NumericInput
                                                                value={state.stock_replenish >= 0 ? state.stock_replenish : 0}
                                                                name={'stock_replenish'}
                                                                className={
                                                                    history.location.pathname.includes('supplied/view') ?
                                                                        "form-control p-form  br-btn-t btn-disabled" :
                                                                        "form-control p-form  br-btn-t"
                                                                }
                                                                readOnly={true}
                                                            />
                                                            <small className="form-text text-muted" style={{fontSize: 12}}>Este valor se calcula automáticamente</small>
                                                        </>
                                                    )
                                                }
                                                <div className={errors.stock_replenish.bol ? "invalid-input" : "valid-input"}>
                                                    {errors.stock_replenish.msg}
                                                </div>
                                            </div>
                                        </Col>
                                        : null}

                                        {!userIsPayment?.plan?.is_free ?
                                        <Col sm="12" md="6">
                                            <div className="form-group">
                                                <label className="form-label fs-6">Valor de mercadería a reponer</label>
                                                <HelpOutlineIcon
                                                    disabled
                                                    style={{ marginLeft: "1rem", fontSize: "large" }}
                                                    onMouseEnter={() => {
                                                        setHelp('stock_replenish_in_cost')
                                                    }}
                                                    onMouseLeave={() => {
                                                        setHelp('')
                                                    }}
                                                    id="stock_replenish_in_cost"
                                                />
                                                <Tooltip placement="right" isOpen={help === "stock_replenish_in_cost"} target="stock_replenish_in_cost">
                                                    Este es el valor en dinero de las unidades que deberías reponer, utilizando de base el precio de costo de tu producto.
                                                </Tooltip>
                                                {
                                                    history.location.pathname.includes('view') ? (
                                                        <>
                                                            <NumericInput
                                                                value={state.stock_replenish_in_cost}
                                                                name={'stock_replenish_in_cost'}
                                                                className={"btn-disabled form-control p-form  br-btn-t"}
                                                                readOnly={true}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <NumericInput
                                                                value={state.stock_replenish_in_cost >= 0 ? state.stock_replenish_in_cost : 0}
                                                                name={'stock_replenish_in_cost'}
                                                                className={
                                                                    history.location.pathname.includes('supplied/view') ?
                                                                        "form-control p-form  br-btn-t btn-disabled" :
                                                                        "form-control p-form  br-btn-t"
                                                                }
                                                                readOnly={true}
                                                            />
                                                            <small className="form-text text-muted" style={{fontSize: 12}}>Este valor se calcula automáticamente</small>
                                                        </>
                                                    )
                                                }
                                                <div className={errors.stock_replenish_in_cost.bol ? "invalid-input" : "valid-input"}>
                                                    {errors.stock_replenish_in_cost.msg}
                                                </div>
                                            </div>
                                        </Col>
                                        : null}
                                    </Row>
                                </Col>
                            </Row>
                        </form>
                    </div>
                </>
            </div>
            <Row style={window.innerWidth > 991 ? null : { display: "flex" }}>
                <div
                    className={window.innerWidth > 991 ? "col" : null}
                    style={
                        history.location.pathname.includes('view') ?
                            { display: "flex", justifyContent: "space-between" } :
                            window.innerWidth < 992 ? { width: "75%" } :
                                null
                    }
                >
                    {
                        id ? (
                            <>
                                <Link to={back}>
                                    <button type="submit" className="btn btn-outline-secondary  br-btn-t mr-1">Cancelar</button>
                                </Link>
                                <button
                                    className="btn btn-primary  br-btn-t color-white"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        setModal(true)
                                    }}
                                >
                                    Borrar
                                </button>
                            </>
                        ) : null
                    }
                </div>
                {
                    history.location.pathname.includes('view') ?
                        null : (
                            <div
                                style={
                                    window.innerWidth > 991 ?
                                        null : { width: "25%" }
                                }
                                className={
                                    window.innerWidth > 991 ?
                                        "col d-flex justify-content-flex-end mb-3" :
                                        "d-flex justify-content-flex-end mb-3"
                                }
                            >
                                {
                                    history.location.pathname.includes('supplies') ? (
                                        !id ? (
                                            <Link to={back}>
                                                <button type="submit" className="btn btn-outline-secondary  br-btn-t mr-1">
                                                    {
                                                        history.location.pathname.includes('view') ?
                                                            'Volver' : 'Cancelar'
                                                    }
                                                </button>
                                            </Link>
                                        ) : null
                                    ) : (
                                        null
                                    )
                                }
                                {
                                    !disabled ? (
                                        <button type="submit" id='save-btn' className="btn background-secondary  br-btn-t color-white" onClick={e => {
                                            onSubmit(e);
                                        }}>Guardar</button>
                                    ) : (
                                        <button type="submit" className="btn background-secondary  br-btn-t color-white" disabled={true}>
                                            <span className="spinner-border spinner-border-sm">
                                                <span className="sr-only"></span>
                                            </span>
                                        </button>
                                    )
                                }
                            </div>
                        )
                }
            </Row>
        </>
    )
}