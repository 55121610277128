import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import PeopleIcon from '@material-ui/icons/People';
import Logo from '../assets/img/logo_lg.png';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SettingsIcon from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/Home';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { useSelector } from 'react-redux';
import LogoTXT from './LogoTXT';
import { Modal } from 'react-bootstrap';
import { getConfig } from '../redux/action';

export default function MenuLateral({ click, setClick, active, sub, setSub, data }) {
    
    const [state, setState] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const [additionalSalaries, setAdditionalSalaries] = useState([]);
    const [modalSave, setModalSave ] = useState(null)
    const history = useHistory();

    const location = useLocation();

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setClick(()=> !click)
                }
            }
    
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);

    // useOutsideAlerter(wrapperRef);

    const getAdditionalSalaries = () => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Token ${localStorage.getItem('flor_emprendedora')}`)
        myHeaders.append("Content-Type", 'application/json')
        
        
        fetch(`${process.env.REACT_APP_URL_API}/api/entrepreneur-salary/get-additional-salary-names/`, {
            'method': "GET",
            'headers': myHeaders
        })
            .then(resp => resp?.json())
            .then(json => {
                //let value = json.results && json.results.length ? parseInt(json.results[0].value) : 0
                let salaries = json

                fetch(`${process.env.REACT_APP_URL_API}/api/config/global/?key=additional_salaries`, {
                    'method': "GET",
                    'headers': myHeaders
                })
                    .then(resp => resp?.json())
                    .then(json => {
                        let value = json.results && json.results.length ? parseInt(json.results[0].value) : 0
                        let salariesLength = salaries.length
                        
                        for (let i = 0; i < value - salariesLength; i++){
                            salaries.push({"name": "Sueldo Soci@"})
                        }
                        setAdditionalSalaries(salaries)
                        console.log(salaries)
                    })
            })
        
        
    }

    useEffect(() => {
        if (window.innerWidth < 992) {
            setIsMobile(true)
        } 
        
        if(location.pathname.indexOf('/products') > -1 || location.pathname.indexOf('/supplies') > -1 || location.pathname.indexOf('/packaging') > -1){
            setSub(true)
            setState('product')
        }
        else if(location.pathname.indexOf('/commercial-data') > -1 || location.pathname.indexOf('/withdrawals') > -1 || location.pathname.indexOf('/invested') > -1){
            setSub(true)
            setState('accounting')
        }

        getAdditionalSalaries();

    }, []);

    window.addEventListener('build', (e) => {
        getAdditionalSalaries();
    }, false);

    const userIsPayment = useSelector(stee => stee?.userPremiumPay)
    console.log("Limites:", userIsPayment?.plan?.is_free)
    console.log('userIsPayment 123', userIsPayment)

    return (
        <>
            <div
                className={
                    !click || isMobile ?
                        `wrapper vertical-layout navbar-floating footer-static vertical-menu-modern menu-expanded ${click && isMobile ? 'mobile-hide' : 'mobile-show'}` :
                        `wrapper vertical-layout navbar-floating footer-static vertical-menu-modern menu-collapsed`
                }
            >
                <div
                    className={
                        !click || isMobile ?
                            "main-menu menu-fixed menu-shadow menu-light expanded" :
                            "main-menu menu-fixed menu-shadow menu-light"
                    }
                ref={wrapperRef}
                onMouseEnter={() => !isMobile ? setClick(false) : null}
                // onMouseOut={() => setClick(!click)}
                >
                    <div className="navbar-header">
                        <ul
                            className={click || isMobile? "nav flex-row align-items-center" : "nav flex-row align-items-center justify-content-center"}
                            style={click || isMobile? { justifyContent: "flex-start !important" } : null}
                        >
                            { //history.location.pathname.includes('view')
                                !click || isMobile ? (
                                    history.location.pathname.includes('edit') || history.location.pathname.includes('create') || history.location.pathname.includes('setting') ?
                                        <li className="nav-item" style={{ width: "80%" }}>
                                            <Link className="brand-logo" to="#" onClick={(e)=>{
                                                setModalSave("/dashboard")
                                            }}>
                                                <LogoTXT size={0.78} color="#6e6b7b" />
                                                {/* <img src={Logo} alt="logo" style={{ width: "100%" }} /> */}
                                            </Link>
                                        </li>
                                    :
                                        <li className="nav-item" style={{ width: "80%" }}>
                                            <Link className="brand-logo" to="/dashboard">
                                                <LogoTXT size={0.78} color="#6e6b7b" />
                                                {/* <img src={Logo} alt="logo" style={{ width: "100%" }} /> */}
                                            </Link>
                                        </li>
                                ) : null
                            }
                            <li style={!click || isMobile ? { marginLeft: "0" } : { width: '80px' }} className={!click || isMobile ? null : 'd-flex align-items-center justify-content-center'}>
                                <button className="nav-link btn modern-nav-toggle cursor-pointer" onClick={() => setClick(!click)}>
                                    {!click || isMobile ?
                                            <ChevronLeft style={{ cursor: "pointer", fontSize: 35, color: '#da1275' }} />   
                                        :
                                            <ChevronRight style={{ cursor: "pointer", fontSize: 35, color: '#da1275' }} />
                                    }
                                    {/* <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="26"
                                        height="26"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        datatour="toggle-icon"
                                        className="text-primary toggle-icon d-xl-block"
                                    >
                                        <circle cx="12" cy="12" r="10"></circle>
                                        <circle cx="12" cy="12" r="3"></circle>
                                    </svg> */}
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div
                        className={
                            !click || isMobile ?
                                "scrollbar-container main-menu-content ps ps--active-y" :
                                "scrollbar-container main-menu-content ps ps--active-y ps--active-x"
                        }
                    >
                        <ul className="navigation navigation-main">
                            <li className="navigation-header">
                                {
                                    !click || isMobile ? 'Menu' : ''
                                }
                            </li>
                            <li className="menu-header-items">
                                {
                                    !click || isMobile ? (
                                        <>
                                            {
                                                history.location.pathname.includes('edit') || history.location.pathname.includes('create') || history.location.pathname.includes('setting') ?
                                                    <Link
                                                        className={active === "dashboard" ? "d-flex align-items-center active menu-items-lat" : "d-flex align-items-center menu-items-lat"}
                                                        to="#"
                                                        onClick={
                                                            (isMobile) ? ()=>{
                                                                setClick(!click)
                                                                setModalSave("/dashboard")
                                                            }: () => setModalSave("/dashboard")
                                                        }
                                                    >
                                                        <HomeIcon />
                                                        <span className="menu-item text-truncate">Inicio</span>
                                                    </Link>
                                                :
                                                    <Link
                                                        className={active === "dashboard" ? "d-flex align-items-center active menu-items-lat" : "d-flex align-items-center menu-items-lat"}
                                                        to="/dashboard"
                                                        onClick={(isMobile) ? ()=>setClick(!click) : null}
                                                    >
                                                        <HomeIcon />
                                                        <span className="menu-item text-truncate">Inicio</span>
                                                    </Link>
                                            }

                                            {!userIsPayment?.plan?.is_free ? 
                                                history.location.pathname.includes('edit') || history.location.pathname.includes('create') || history.location.pathname.includes('setting') ?
                                                    <Link
                                                        className={active === "proveedores" ? "d-flex align-items-center active menu-items-lat" : "d-flex align-items-center menu-items-lat"}
                                                        to="#"
                                                        onClick={
                                                            (isMobile) ? ()=>{
                                                                setClick(!click)
                                                                setModalSave("/vendors")
                                                            }: () => setModalSave("/vendors")
                                                        }
                                                    >
                                                        <PeopleIcon/>
                                                        <span className="menu-item text-truncate">Proveedores</span>
                                                    </Link>
                                                :
                                                    <Link
                                                        className={active === "proveedores" ? "d-flex align-items-center active menu-items-lat" : "d-flex align-items-center menu-items-lat"}
                                                        to="/vendors"
                                                        onClick={(isMobile) ? ()=>setClick(!click) : null}
                                                    >
                                                        <PeopleIcon />
                                                        <span className="menu-item text-truncate">Proveedores</span>
                                                    </Link>
                                            : null}

                                            {!userIsPayment?.plan?.is_free ?
                                                history.location.pathname.includes('edit') || history.location.pathname.includes('create') || history.location.pathname.includes('setting') ?
                                                    <Link
                                                        className={active === "clientes" ? "d-flex align-items-center active menu-items-lat" : "d-flex align-items-center menu-items-lat"}
                                                        to="#"
                                                        onClick={
                                                            (isMobile) ? ()=>{
                                                                setClick(!click)
                                                                setModalSave("/clients")
                                                            }: () => setModalSave("/clients")
                                                        }
                                                    >
                                                        <AssignmentIndIcon/>
                                                        <span className="menu-item text-truncate">Clientes</span>
                                                    </Link>
                                                :
                                                    <Link
                                                        className={active === "clientes" ? "d-flex align-items-center active menu-items-lat" : "d-flex align-items-center menu-items-lat"}
                                                        to="/clients"
                                                        onClick={(isMobile) ? ()=>setClick(!click) : null}
                                                    >
                                                        <AssignmentIndIcon />
                                                        <span className="menu-item text-truncate">Clientes</span>
                                                    </Link>
                                            : null}

                                            {
                                                history.location.pathname.includes('edit') || history.location.pathname.includes('create') || history.location.pathname.includes('setting') ?
                                                    <Link
                                                        className={active === "incomes" ? "d-flex align-items-center active menu-items-lat" : "d-flex align-items-center menu-items-lat"}
                                                        to="#"
                                                        onClick={
                                                            (isMobile) ? ()=>{
                                                                setClick(!click)
                                                                setModalSave("/incomes")
                                                            }: () => setModalSave("/incomes")
                                                        }
                                                    >
                                                        <DataUsageIcon/>
                                                        <span className="menu-item text-truncate">Ventas</span>
                                                    </Link>
                                                :
                                                    <Link
                                                        className={active === "incomes" ? "d-flex align-items-center active menu-items-lat" : "d-flex align-items-center menu-items-lat"}
                                                        to="/incomes"
                                                        onClick={(isMobile) ? ()=>setClick(!click) : null}
                                                    >
                                                        <DataUsageIcon />
                                                        <span className="menu-item text-truncate">Ventas</span>
                                                    </Link>
                                            }
                                            
                                            <Link
                                                to='#'
                                                onClick={() => {
                                                    if (state === 'product') {
                                                        setSub(false)
                                                        setState(null)
                                                    } else {
                                                        setSub(true)
                                                        setState('product')
                                                    }
                                                }}
                                                className={"d-flex align-items-center has-sub menu-items-lat is-dropdown " + (sub && state === 'product' ? 'open' : '')}
                                            >
                                                <ShoppingBasketIcon style={{ marginRight: '1.1rem' }} />
                                                <span className="menu-item text-truncate">Producto</span>
                                            </Link>
                                            {
                                                sub && state === 'product' ? (
                                                    <ul className="menu-content" style={{ padding: 0 }}>
                                                        {data === 'true' ? 
                                                        <li className="nav-item">
                                                            {
                                                                history.location.pathname.includes('edit') || history.location.pathname.includes('create') || history.location.pathname.includes('setting') ?
                                                                    <Link
                                                                        className={active === "supplies" ? "d-flex align-items-center active menu-items-lat" : "d-flex align-items-center menu-items-lat"}
                                                                        to="#"
                                                                        onClick={
                                                                            (isMobile) ? ()=>{
                                                                                setClick(!click)
                                                                                setModalSave("/supplies")
                                                                            }: () => setModalSave("/supplies")
                                                                        }
                                                                    >
                                                                        <FiberManualRecordIcon style={{ marginRight: "1.1rem", fontSize: "small" }} />
                                                                        <span className="menu-item text-truncate">Insumos</span>
                                                                    </Link>
                                                                :
                                                                    <Link
                                                                        className={active === "supplies" ? "d-flex align-items-center active menu-items-lat" : "d-flex align-items-center menu-items-lat"}
                                                                        to="/supplies"
                                                                        onClick={(isMobile) ? ()=>setClick(!click) : null}
                                                                    >
                                                                        <FiberManualRecordIcon style={{ marginRight: "1.1rem", fontSize: "small" }} />
                                                                        <span className="menu-item text-truncate">Insumos</span>
                                                                    </Link>
                                                            }
                                                        </li>
                                                        : null}
                                                        <li className="nav-item">
                                                            {
                                                                history.location.pathname.includes('edit') || history.location.pathname.includes('create') || history.location.pathname.includes('setting') ?
                                                                    <Link
                                                                        className={active === "packaging" ? "d-flex align-items-center active menu-items-lat" : "d-flex align-items-center menu-items-lat"}
                                                                        to="#"
                                                                        onClick={
                                                                            (isMobile) ? ()=>{
                                                                                setClick(!click)
                                                                                setModalSave("/packaging")
                                                                            }: () => setModalSave("/packaging")
                                                                        }
                                                                    >
                                                                        <FiberManualRecordIcon style={{ marginRight: "1.1rem", fontSize: "small" }} />
                                                                        <span className="menu-item text-truncate">Packaging</span>
                                                                    </Link>
                                                                :
                                                                    <Link
                                                                        className={active === "packaging" ? "d-flex align-items-center active menu-items-lat" : "d-flex align-items-center menu-items-lat"}
                                                                        to="/packaging"
                                                                        onClick={(isMobile) ? ()=>setClick(!click) : null}
                                                                    >
                                                                        <FiberManualRecordIcon style={{ marginRight: "1.1rem", fontSize: "small" }} />
                                                                        <span className="menu-item text-truncate">Packaging</span>
                                                                    </Link>
                                                            }
                                                        </li>
                                                        <li className="nav-item">
                                                            {
                                                                history.location.pathname.includes('edit') || history.location.pathname.includes('create') || history.location.pathname.includes('setting') ?
                                                                    <Link
                                                                        className={active === "products" ? "d-flex align-items-center active menu-items-lat" : "d-flex align-items-center menu-items-lat"}
                                                                        to="#"
                                                                        onClick={
                                                                            (isMobile) ? ()=>{
                                                                                setClick(!click)
                                                                                setModalSave("/products")
                                                                            }: () => setModalSave("/products")
                                                                        }
                                                                    >
                                                                        <FiberManualRecordIcon style={{ marginRight: "1.1rem", fontSize: "small" }} />
                                                                        <span className="menu-item text-truncate">Productos</span>
                                                                    </Link>
                                                                :
                                                                    <Link
                                                                        to="/products"
                                                                        className={
                                                                            active === 'products' ? "d-flex align-items-center active" : "d-flex align-items-center"
                                                                        }
                                                                        onClick={(isMobile) ? ()=>setClick(!click) : null}
                                                                    >
                                                                        <FiberManualRecordIcon style={{ marginRight: "1.1rem", fontSize: "small" }} />
                                                                        <span className="menu-item text-truncate">Productos</span>
                                                                    </Link>
                                                            }
                                                            
                                                        </li>
                                                    </ul>
                                                ) : null
                                            }
                                            <Link
                                                to='#'
                                                onClick={() => {
                                                    if (state === 'accounting') {
                                                        setSub(false)
                                                        setState(null)
                                                    } else {
                                                        setSub(true)
                                                        setState('accounting')
                                                    }
                                                }}
                                                className={"d-flex align-items-center has-sub menu-items-lat is-dropdown " + (sub && state === 'accounting' ? 'open' : '')}
                                            >
                                                <AccountBalanceWalletIcon style={{ marginRight: '1.1rem' }} />
                                                <span className="menu-item text-truncate">Contabilidad</span>
                                            </Link>
                                            {
                                                sub && state === 'accounting' ? (
                                                    <ul className="menu-content" style={{ padding: 0 }}>
                                                        <li className="nav-item">
                                                            {
                                                                history.location.pathname.includes('edit') || history.location.pathname.includes('create') || history.location.pathname.includes('setting') ?
                                                                    <Link
                                                                        className={active === "commercial-data" ? "d-flex align-items-center active menu-items-lat" : "d-flex align-items-center menu-items-lat"}
                                                                        to="#"
                                                                        onClick={
                                                                            (isMobile) ? ()=>{
                                                                                setClick(!click)
                                                                                setModalSave("/commercial-data")
                                                                            }: () => setModalSave("/commercial-data")
                                                                        }
                                                                    >
                                                                        <FiberManualRecordIcon style={{ marginRight: "1.1rem", fontSize: "small" }} />
                                                                        <span className="menu-item text-truncate">Gastos comerciale</span>
                                                                    </Link>
                                                                :
                                                                    <Link
                                                                        to="/commercial-data"
                                                                        className={
                                                                            active === 'commercial-data' ?
                                                                                "d-flex align-items-center active" :
                                                                                "d-flex align-items-center"
                                                                        }
                                                                        onClick={(isMobile) ? ()=>setClick(!click) : null}
                                                                    >
                                                                        <FiberManualRecordIcon style={{ marginRight: "1.1rem", fontSize: "small" }} />
                                                                        <span className="menu-item text-truncate">Gastos comerciales</span>
                                                                    </Link>
                                                            }
                                                        </li>

                                                        {!userIsPayment?.plan?.is_free ? 
                                                        <li className="nav-item">
                                                            {
                                                                history.location.pathname.includes('edit') || history.location.pathname.includes('create') || history.location.pathname.includes('setting') ?
                                                                    <Link
                                                                        className={active === "expenses" ? "d-flex align-items-center active menu-items-lat" : "d-flex align-items-center menu-items-lat"}
                                                                        to="#"
                                                                        onClick={
                                                                            (isMobile) ? ()=>{
                                                                                setClick(!click)
                                                                                setModalSave("/withdrawals")
                                                                            }: () => setModalSave("/withdrawals")
                                                                        }
                                                                    >
                                                                        <FiberManualRecordIcon style={{ marginRight: "1.1rem", fontSize: "small" }} />
                                                                        <span className="menu-item text-truncate">Egresos de dinero</span>
                                                                    </Link>
                                                                :
                                                                    <Link
                                                                        to="/withdrawals"
                                                                        className={
                                                                            active === 'expenses' ? "d-flex align-items-center active" : "d-flex align-items-center"
                                                                        }
                                                                        onClick={(isMobile) ? ()=>setClick(!click) : null}
                                                                    >
                                                                        <FiberManualRecordIcon style={{ marginRight: "1.1rem", fontSize: "small" }} />
                                                                        <span className="menu-item text-truncate">Egresos de dinero</span>
                                                                    </Link>
                                                            }
                                                        </li>
                                                        : null}

                                                        {!userIsPayment?.plan?.is_free ? 
                                                        <li className="nav-item">
                                                            {
                                                                history.location.pathname.includes('edit') || history.location.pathname.includes('create') || history.location.pathname.includes('setting') ?
                                                                    <Link
                                                                        className={active === "invested" ? "d-flex align-items-center active menu-items-lat" : "d-flex align-items-center menu-items-lat"}
                                                                        to="#"
                                                                        onClick={
                                                                            (isMobile) ? ()=>{
                                                                                setClick(!click)
                                                                                setModalSave("/invested")
                                                                            }: () => setModalSave("/invested")
                                                                        }
                                                                    >
                                                                        <FiberManualRecordIcon style={{ marginRight: "1.1rem", fontSize: "small" }} />
                                                                        <span className="menu-item text-truncate">Bienes de uso</span>
                                                                    </Link>
                                                                :
                                                                    <Link
                                                                        to="/invested"
                                                                        className={
                                                                            active === 'invested' ? "d-flex align-items-center active" : "d-flex align-items-center"
                                                                        }
                                                                        onClick={(isMobile) ? ()=>setClick(!click) : null}
                                                                    >
                                                                        <FiberManualRecordIcon style={{ marginRight: "1.1rem", fontSize: "small" }} />
                                                                        <span className="menu-item text-truncate">Bienes de uso</span>
                                                                    </Link>
                                                            }
                                                        </li>
                                                        : null}
                                                        
                                                    </ul>
                                                ) : null
                                            }
                                            {
                                                history.location.pathname.includes('edit') || history.location.pathname.includes('create') || history.location.pathname.includes('setting') ?
                                                    <Link
                                                        className={active === "setting" ? "d-flex align-items-center active menu-items-lat" : "d-flex align-items-center menu-items-lat"}
                                                        to="#"
                                                        onClick={
                                                            (isMobile) ? ()=>{
                                                                setClick(!click)
                                                                setModalSave("/setting")
                                                            }: () => setModalSave("/setting")
                                                        }
                                                    >
                                                        <SettingsIcon />
                                                        <span className="menu-item text-truncate">Configuración</span>
                                                    </Link>
                                                :
                                                    <Link
                                                        className={active === "setting" ? "d-flex align-items-center active menu-items-lat" : "d-flex align-items-center menu-items-lat"}
                                                        to="/setting"
                                                        onClick={(isMobile) ? ()=>setClick(!click) : null}
                                                    >
                                                        <SettingsIcon />
                                                        <span className="menu-item text-truncate">Configuración</span>
                                                    </Link>
                                            }
                                            
                                            {/* <Link
                                                className={active === "entrepreneurial-salary" ? "d-flex align-items-center active menu-items-lat" : "d-flex align-items-center menu-items-lat"}
                                                to="/entrepreneur-salary"
                                                onClick={(isMobile) ? ()=>setClick(!click) : null}
                                            >
                                                <span className="material-icons" style={{marginRight: '1.1rem'}}>calculate</span>
                                                <span className="menu-item text-truncate">Sueldo emprendedor</span>
                                            </Link> */}
                                            <Link
                                                to='#'
                                                onClick={() => {
                                                    if (state === 'entrepreneurial-salary') {
                                                        setSub(false)
                                                        setState(null)
                                                    } else {
                                                        setSub(true)
                                                        setState('entrepreneurial-salary')
                                                    }
                                                }}
                                                className={"d-flex align-items-center has-sub menu-items-lat is-dropdown " + (sub && state === 'entrepreneurial-salary' ? 'open' : '')}
                                            >
                                                <span className="material-icons" style={{marginRight: '1.1rem'}}>calculate</span>
                                                <span className="menu-item text-truncate">Sueldo emprendedor</span>
                                            </Link>
                                            {
                                                sub && state === 'entrepreneurial-salary' ? (
                                                    <ul className="menu-content" style={{ padding: 0 }}>
                                                        {
                                                            history.location.pathname.includes('edit') || history.location.pathname.includes('create') || history.location.pathname.includes('setting') ?
                                                                <li className="nav-item">    
                                                                    <Link
                                                                        className={
                                                                            active === 'entrepreneur-salary' && location.pathname === '/entrepreneur-salary/1' ?
                                                                                "d-flex align-items-center active" :
                                                                                "d-flex align-items-center"
                                                                        }
                                                                        to="#"
                                                                        onClick={
                                                                            (isMobile) ? ()=>{
                                                                                setClick(!click)
                                                                                setModalSave("/entrepreneur-salary/1")
                                                                            }: () => setModalSave("/entrepreneur-salary/1")
                                                                        }
                                                                    >
                                                                        <FiberManualRecordIcon style={{ marginRight: "1.1rem", fontSize: "small" }} />
                                                                        <span className="menu-item text-truncate">Sueldo</span>
                                                                    </Link>
                                                                </li>
                                                            :
                                                                <li className="nav-item">
                                                                    <Link
                                                                        to="/entrepreneur-salary/1"
                                                                        className={
                                                                            active === 'entrepreneur-salary' && location.pathname === '/entrepreneur-salary/1' ?
                                                                                "d-flex align-items-center active" :
                                                                                "d-flex align-items-center"
                                                                        }
                                                                        onClick={(isMobile) ? ()=>setClick(!click) : null}
                                                                    >
                                                                        <FiberManualRecordIcon style={{ marginRight: "1.1rem", fontSize: "small" }} />
                                                                        <span className="menu-item text-truncate">Sueldo</span>
                                                                    </Link>
                                                                </li>
                                                        }

                                                        {additionalSalaries && additionalSalaries.length && !userIsPayment?.plan?.is_free ? additionalSalaries.map((item, key) => {
                                                            return (
                                                                history.location.pathname.includes('edit') || history.location.pathname.includes('create') || history.location.pathname.includes('setting') ?
                                                                    <li key={key} className="nav-item">  
                                                                        <Link
                                                                            className={
                                                                                active === 'entrepreneur-salary' && location.pathname === '/entrepreneur-salary/'+ key+2  ?
                                                                                    "d-flex align-items-center active" :
                                                                                    "d-flex align-items-center"
                                                                            }
                                                                            to="#"
                                                                            onClick={
                                                                                (isMobile) ? ()=>{
                                                                                    setClick(!click)
                                                                                    setModalSave(`/entrepreneur-salary/${key+2}`)
                                                                                }: () => setModalSave(`/entrepreneur-salary/${key+2}`)
                                                                            }
                                                                        >
                                                                            <FiberManualRecordIcon style={{ marginRight: "1.1rem", fontSize: "small" }} />
                                                                            <span className="menu-item text-truncate">{item.name} - Sueldo {key+2}</span>
                                                                        </Link>
                                                                    </li>
                                                                :
                                                                    <li key={key} className="nav-item">
                                                                        <Link
                                                                            to={`/entrepreneur-salary/${key+2}`}
                                                                            className={
                                                                                active === 'entrepreneur-salary' && location.pathname === '/entrepreneur-salary/'+ key+2 ?
                                                                                    "d-flex align-items-center active" :
                                                                                    "d-flex align-items-center"
                                                                            }
                                                                            onClick={(isMobile) ? ()=>setClick(!click) : null}
                                                                        >
                                                                            <FiberManualRecordIcon style={{ marginRight: "1.1rem", fontSize: "small" }} />
                                                                            <span className="menu-item text-truncate">{item.name} - Sueldo {key+2}</span>
                                                                        </Link>
                                                                    </li>
                                                            )
                                                        }) : null}
                                                    </ul>
                                                ) : null
                                            }
                                            {
                                                history.location.pathname.includes('edit') || history.location.pathname.includes('create') || history.location.pathname.includes('setting') ?
                                                <Link
                                                    className={active === "Pro" ? "d-flex align-items-center active menu-items-lat" : "d-flex align-items-center menu-items-lat"}
                                                    to="#"
                                                    onClick={
                                                        (isMobile) ? ()=>{
                                                            setClick(!click)
                                                            setModalSave(`/planes`)
                                                        }: () => setModalSave(`/planes`)
                                                    }
                                                >
                                                    <AttachMoneyIcon />
                                                    <span className="menu-item text-truncate">Planes</span>
                                                </Link>
                                            :
                                                <Link
                                                    className={active === "Pro" ? "d-flex align-items-center active menu-items-lat" : "d-flex align-items-center menu-items-lat"}
                                                    to="/planes"
                                                    onClick={(isMobile) ? ()=>setClick(!click) : null}
                                                >
                                                    <AttachMoneyIcon />
                                                    <span className="menu-item text-truncate">Planes</span>
                                                </Link>
                                            }
                                        </>
                                    ) : (
                                        <>
                                            <Link
                                                className={active === "dashboard" ?
                                                    "d-flex align-items-center padding-0 justify-content-center btn-height-3 active" :
                                                    "d-flex align-items-center padding-0 justify-content-center btn-height-3"
                                                }
                                                to="/dashboard"
                                            >
                                                <DashboardIcon className="margin-right-0"/>
                                            </Link>
                                            <Link
                                                className={active === "proveedores" ?
                                                    "d-flex align-items-center padding-0 justify-content-center btn-height-3 active" :
                                                    "d-flex align-items-center padding-0 justify-content-center btn-height-3"
                                                }
                                                to="/vendors"
                                            >
                                                <PeopleIcon className="margin-right-0" />
                                            </Link>
                                            <Link
                                                className={active === "clientes" ?
                                                    "d-flex align-items-center padding-0 justify-content-center btn-height-3 active" :
                                                    "d-flex align-items-center padding-0 justify-content-center btn-height-3"
                                                }
                                                to="/clients"
                                            >
                                                <AssignmentIndIcon style={{ margin: 0 }} />
                                            </Link>
                                            <Link
                                                className={active === "incomes" ?
                                                    "d-flex align-items-center padding-0 justify-content-center btn-height-3 active" :
                                                    "d-flex align-items-center padding-0 justify-content-center btn-height-3"
                                                }
                                                to="/incomes"
                                            >
                                                <DataUsageIcon style={{ margin: 0 }} />
                                            </Link>
                                            <Link
                                                className={active === "products" || active === "supplies" || active === "packaging" ?
                                                    "d-flex align-items-center padding-0 justify-content-center btn-height-3 active" :
                                                    "d-flex align-items-center padding-0 justify-content-center btn-height-3"
                                                }
                                                to="#"
                                                onClick={() => {
                                                    setClick(!click)
                                                    setSub(true)
                                                    setState('product')
                                                }}
                                            >
                                                <ShoppingBasketIcon style={{ margin: 0 }} />
                                            </Link>
                                            <Link
                                                className={active === "expenses" || active === "commercial-data" ?
                                                    "d-flex align-items-center padding-0 justify-content-center btn-height-3 active" :
                                                    "d-flex align-items-center padding-0 justify-content-center btn-height-3"
                                                }
                                                to="#"
                                                onClick={() => {
                                                    setClick(!click)
                                                    setSub(true)
                                                    setState('accounting')
                                                }}
                                            >
                                                <AccountBalanceWalletIcon style={{ margin: 0 }} />
                                            </Link>
                                            <Link
                                                className={active === "setting" ?
                                                    "d-flex align-items-center padding-0 justify-content-center btn-height-3 active" :
                                                    "d-flex align-items-center padding-0 justify-content-center btn-height-3"
                                                }
                                                to="/setting"
                                            >
                                                <SettingsIcon className="margin-right-0" />
                                            </Link>
                                            
                                        </>
                                    )
                                }
                            </li>
                        </ul>
                    </div>
                </div>
            </div >
            <div 
                className='fade in'
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1030,
                    visibility: isMobile ? (click && isMobile ? 'hidden' : 'visible') : 'hidden',
                    opacity: click && isMobile ? 0 : 1
                }}
                onClick={() => setClick(isMobile)}
            />
            <Modal show={modalSave} className="modal-danger">
                <Modal.Header>
                    <button type="button" className="close color-primary" onClick={() => setModalSave(null)}>
                        <span>×</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <p>Estás saliendo de la edición. ¿Querés guardar los cambios?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className="btn btn-outline-secondary btn-height-3 br-btn-t mr-1" onClick={() =>{
                        history.push(modalSave)
                        setModalSave(null)
                    }}
                    >Cancelar</button>
                    <button
                        type="submit"
                        className="btn btn-primary btn-height-3 br-btn-t color-white"
                        onClick={(e) => {
                            const saveBtn = document.getElementById('save-btn')
                            saveBtn.click()

                            setTimeout(function() {
                                const errors = document.getElementsByClassName('error-input')
                                if (errors.length === 0){
                                    history.push(modalSave)
                                }
                            }, 1000);

                            setModalSave(null)
                        }}
                    >
                        Guardar
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}