import React from 'react';
import { useHistory } from 'react-router';
import { Row, Col } from 'reactstrap';
import Skeleton from 'react-loading-skeleton'

export default function CardTotal ({total, title, totalPayments, titlePayments, isTwin}) {
    const history = useHistory()
    return (
        <Row className="mb-2" style={{ marginTop: "1rem" }}>
            <Col sm="6" className="mb-1" style={window.innerWidth < 992 ? { width: '100%'} : null}>
                <div className="card">
                    <div className="card-body d-flex align-items-center justify-content-sb">
                        <div className="margin-1 d-flex flex-direction-column align-items-flex-start" style={window.innerWidth < 992 ? { paddingRight: '28%'} : null}>
                            <h2 className="font-weight-bolder text-left" style={{minWidth: 100}}>{total !== null ? `$ ${total.toLocaleString('de-DE')}` : <Skeleton />}</h2>
                            <span className="b-avatar-custom w-100">
                                <span className="fs-6">{total !== null ? title : <Skeleton />}</span>
                            </span>
                        </div>
                        <span
                            className="b-avatar badge-light-success rounded-circle align-items-center" 
                            style={{ height: "45px", width: "45px"}}
                        >
                            <span className="b-avatar-custom">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-dollar-sign"
                                >
                                    <line x1="12" y1="1" x2="12" y2="23"></line>
                                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                </svg>
                            </span>
                        </span>
                    </div>
                </div>
            </Col>
            {
                isTwin?(
                    titlePayments !== '' || titlePayments !== undefined ? (
                        <Col sm='6' style={window.innerWidth < 992 ? { width: '100%' } : null}>
                            <div className="card text-center">
                                <div className="card-body d-flex align-items-center justify-content-sb">
                                    <div className="margin-1 d-flex flex-direction-column align-items-flex-start">
                                        <h2 className="font-weight-bolder text-left" style={{minWidth: 100}}>{totalPayments !== null ? `$ ${totalPayments.toLocaleString('de-DE')}` : <Skeleton />}</h2>
                                        <span className="b-avatar-custom w-100">
                                            <span className="fs-6" >{totalPayments !== null ? titlePayments : <Skeleton />}</span>
                                        </span>
                                    </div>
                                    <span 
                                        className="b-avatar badge-light-success rounded-circle align-items-center" 
                                        style={{ height: "45px", width: "45px"}}
                                    >
                                        <span className="b-avatar-custom">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24px"
                                                height="24px"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="feather feather-dollar-sign"
                                            >
                                                <line x1="12" y1="1" x2="12" y2="23"></line>
                                                <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                            </svg>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </Col>
                    ) : null
                ):null
            }
        </Row>
    )
}