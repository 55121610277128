import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory, useParams } from 'react-router';
import { NumericInput } from './NumericInput';

export default function FormWithdrawals({ expensesCommercial, title, state, setModal, errors, setState, back, onSubmit, disabled, vendors, idVendor, setAddVendors, setAddCommercialData, readOnly, settings}) {
    const history = useHistory();
    const { id } = useParams();
    const [change, setChange] = React.useState(false)
    let url = new URL(window.location.href)

    let type = url.searchParams.get('type');
    let spend = url.searchParams.get('spend');

    console.log('useParams();', url.searchParams.get('type'), url.searchParams.get('spend'))
    
    const value = expensesCommercial?.filter(element => element?.id === parseFloat(state?.spend))[0]

    useEffect(()=> {
        if (state.amount_whithout_fees){
            console.log(state.method_payment)
            if ((state.method_payment === 'mercado_pago' || state.method_payment === 'rapipago') && settings?.platform_fee && settings?.quota_financing && settings?.platform_fee != '0' && settings?.quota_financing != '0' ) 
            setState({
                ...state,
                ['amount']: parseFloat(state.amount_whithout_fees) + parseFloat(state.amount_whithout_fees) * parseFloat(settings.platform_fee) / 100 + parseFloat(settings.quota_financing) ,
            })
            else setState({
                ...state,
                ['amount']: parseFloat(state.amount_whithout_fees),
            })
        }

    }, [state.method_payment, state.amount_whithout_fees])

    const setSpend = (spend) => {
        setState({
            ...state,
            type: "expenses",
            spend: spend,
            amount: expensesCommercial?.filter(element => element?.id === parseFloat(spend))[0]?.value
        })
    }

    useEffect(()=> {
        if(type === 'expenses') {
            setSpend(spend)
        }
    }, [expensesCommercial])
        
    console.log(state.payment_status)
    return (
        <>
            <div className= {"card mb-3"}>
                {
                    history.location.pathname.includes('products') ? (
                        null
                    ) : (
                        <div className="card-header mt-1">
                            <div className="card-title">
                                <h2>
                                    {title}
                                </h2>
                            </div>
                        </div>
                    )
                }
                <div className="card-body pt-1">
                    <form>
                        <Row>
                            <Col>
                                <div className="form-group mb-0">
                                    <label className="form-label fs-6">Fecha</label>
                                    {
                                        history.location.pathname.includes('view') ? (
                                            <input
                                                type={'date'}
                                                name={'date'}
                                                className={"btn-disabled form-control p-form br-btn-t ml-3"}
                                                value={state.date}
                                                readOnly
                                            />
                                        ) : (
                                            <input
                                                type="date"
                                                name="date"
                                                value={state.date}
                                                className={errors.date.bol ?
                                                    "form-control p-form br-btn-t error-input" :
                                                    "form-control p-form br-btn-t"
                                                }
                                                format={'YYYY-MM-DD'}
                                                onChange={e => {
                                                    setState({
                                                        ...state,
                                                        date: e.target.value
                                                    })
                                                }}
                                            />
                                        )
                                    }
                                    <div
                                        className={errors.date.bol ?
                                            "invalid-input" :
                                            "valid-input"
                                        }
                                    >
                                        {errors.date.msg}
                                    </div>
                                </div>
                                {
                                    history.location.pathname.includes('view') ? (
                                        <>
                                            <label className="form-label fs-6">Tipo</label>
                                            <div className="d-flex align-items-center width-50 justify-content-flex-start mb-1 ml-3">
                                                <div
                                                    className="form-group d-flex align-items-center width-auto margin-bottom-0 margin-0"
                                                >
                                                    {state.type === "expenses" ? "Pago" : null}
                                                    {state.type === "payment" ? "Gasto" : null}
                                                    {state.type === "purchase" ? "Compra" : null}
                                                    {state.type === "workforce" ? "Mano de obra" : null}
                                                    {state.type === "withdrawal" ? "Retiro" : null}
                                                </div>
                                            </div>
                                        </>
                                    ) :
                                        <div className="d-flex align-items-center width-100 justify-content-flex-start mb-1" style={{gap:'8px'}}>
                                            <div
                                                className="form-group d-flex align-items-center width-auto margin-bottom-0 margin-0"
                                            >
                                                <div className="custom-control-primary custom-control custom-radio">
                                                    <input id='check-2' type="radio" name="type" className="custom-control-input" defaultChecked={(state.type === "payment") ? true : null} />
                                                    <label
                                                        htmlFor='check-2'
                                                        className="custom-control-label checked"
                                                        onClick={() => {
                                                            setState({ ...state, type: "payment" })
                                                        }}
                                                    >
                                                        Gasto
                                                    </label>
                                                </div>
                                            </div>
                                            <div
                                                className="form-group d-flex align-items-center width-auto margin-bottom-0 margin-0"
                                            >
                                                <div className="custom-control-primary custom-control custom-radio">
                                                    <input id='check-purc' type="radio" name="type" className="custom-control-input" defaultChecked={(state.type === "purchase") ? true : null}/>
                                                    <label
                                                        htmlFor='check-purc'
                                                        className="custom-control-label checked"
                                                        onClick={() => {
                                                            setState({ ...state, type: "purchase" })
                                                        }}
                                                    >
                                                        Compra
                                                    </label>
                                                </div>
                                            </div>
                                            <div
                                                className="form-group d-flex align-items-center width-auto margin-bottom-0 margin-0"
                                            >
                                                <div className="custom-control-primary custom-control custom-radio">
                                                    <input id='check-work' type="radio" name="type" className="custom-control-input" defaultChecked={(state.type === "workforce") ? true : null}/>
                                                    <label
                                                        htmlFor='check-work'
                                                        className="custom-control-label checked"
                                                        onClick={() => {
                                                            setState({ ...state, type: "workforce" })
                                                        }}
                                                    >
                                                        Mano de obra
                                                    </label>
                                                </div>
                                            </div>
                                            <div
                                                className="form-group d-flex align-items-center width-auto margin-bottom-0 margin-0"
                                            >
                                                <div className="custom-control-primary custom-control custom-radio">
                                                    <input id='check-with' type="radio" name="type" className="custom-control-input" defaultChecked={(state.type === "withdrawal") ? true : null}/>
                                                    <label
                                                        htmlFor='check-with'
                                                        className="custom-control-label checked"
                                                        onClick={() => {
                                                            setState({ ...state, type: "withdrawal" })
                                                        }}
                                                    >
                                                        Retiro
                                                    </label>
                                                </div>
                                            </div>
                                            <div
                                                className="form-group d-flex align-items-center margin-bottom-0 width-auto margin-0"
                                            >
                                                <div className="custom-control-primary custom-control custom-radio">
                                                    <input id='check-3' type="radio" name="type" className="custom-control-input" defaultChecked={(state.type === "expenses") ? true : null}/>
                                                    <label
                                                        htmlFor='check-3'
                                                        className="custom-control-label"
                                                        onClick={() => {
                                                            setState({ ...state, type: "expenses" })
                                                        }}>
                                                        Pago mensual de gasto comercial
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </Col>
                        </Row>
                        {
                            state.type === "payment" || state.type === "purchase" || state.type === "workforce" || state.type === "withdrawal" ? (
                                <>
                                    <Row>
                                        <Col sm="12">
                                            <div className={'form-group'}>
                                                <label className={'form-label fs-6'}>{'Detalle'}</label>
                                                {
                                                    history.location.pathname.includes('view') ? (
                                                        <input
                                                            type={'text'}
                                                            name={'concept'}
                                                            className={"btn-disabled form-control p-form br-btn-t ml-3"}
                                                            value={state.concept}
                                                            readOnly
                                                        />
                                                    ) : (
                                                        <input
                                                            name={'concept'}
                                                            type={'text'}
                                                            className={
                                                                errors.concept.bol ?
                                                                    "form-control p-form br-btn-t error-input" :
                                                                    "form-control p-form br-btn-t"
                                                                
                                                            }
                                                            value={state.concept}
                                                            onChange={e => {
                                                                setState({
                                                                    ...state,
                                                                    [e.target.name]: e.target.value
                                                                })
                                                            }}
                                                        />
                                                    )
                                                }
                                                <div
                                                    className={errors.concept.bol ?
                                                        "invalid-input" :
                                                        "valid-input"
                                                    }
                                                >
                                                    {errors.concept.msg}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="12" md="6">
                                            <div className={'form-group'}>
                                                <label className={'form-label fs-6'}>{'Importe'}</label>
                                                {
                                                    history.location.pathname.includes('view') ? (
                                                        <>
                                                            <NumericInput
                                                                name={'amount_whithout_fees'}
                                                                className={"btn-disabled form-control p-form br-btn-t ml-3"}
                                                                value={state.amount_whithout_fees}
                                                                readOnly={true}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <NumericInput
                                                                name={'amount_whithout_fees'}
                                                                className={errors.amount_whithout_fees?.bol ?
                                                                    "form-control p-form br-btn-t error-input" :
                                                                    "form-control p-form br-btn-t"
                                                                }
                                                                defaultValue={state.amount_whithout_fees}
                                                                onChange={e => {
                                                                    setState({
                                                                        ...state,
                                                                        [e.target.name]: e.target.value,
                                                                    })
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                }
                                                <div
                                                    className={errors.amount_whithout_fees?.bol ?
                                                        "invalid-input" :
                                                        "valid-input"
                                                    }
                                                >
                                                    {errors.amount_whithout_fees?.msg}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="12" md="6">
                                            <div className={'form-group'}>
                                                <label className={'form-label fs-6'}>{'Importe con comisiones de plataforma'}</label>
                                                {
                                                    history.location.pathname.includes('view') ? (
                                                        <>
                                                            <NumericInput
                                                                name={'amount'}
                                                                className={"btn-disabled form-control p-form br-btn-t ml-3"}
                                                                value={state.amount}
                                                                readOnly={true}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <NumericInput
                                                                name={'amount'}
                                                                className={errors.amount.bol ?
                                                                    "form-control p-form br-btn-t error-input" :
                                                                    "form-control p-form br-btn-t"
                                                                }
                                                                value={state.amount}
                                                                disabled={true}
                                                            />
                                                            <input
                                                                name={'amount'}
                                                                type={'number'}
                                                                className={errors.amount.bol ?
                                                                    "form-control p-form br-btn-t error-input" :
                                                                    "form-control p-form br-btn-t"
                                                                }
                                                                value={state.amount}
                                                                disabled
                                                            />
                                                        </>
                                                    )
                                                }
                                                <div
                                                    className={errors.amount.bol ?
                                                        "invalid-input" :
                                                        "valid-input"
                                                    }
                                                >
                                                    {errors.amount.msg}
                                                </div>
                                            </div>
                                        </Col>
                                        
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            <div className={'form-group'}>
                                                <label className={'form-label fs-6'}>{'Medio de pago'}</label>
                                                {
                                                    history.location.pathname.includes('view') ? (
                                                        <input
                                                            type={'text'}
                                                            name={'method_payment'}
                                                            className={"btn-disabled form-control p-form br-btn-t ml-3"}
                                                            value={state?.method_payment?.toUpperCase().replace("_", " ")}
                                                            readOnly
                                                        />
                                                    ) : (
                                                        <select
                                                            onChange={(e) =>{ 
                                                                setState({
                                                                    ...state,
                                                                    ['method_payment']: e.target.value,
                                                                })
                                                                setChange(!change)
                                                            }}
                                                            value={state.method_payment}
                                                            className={
                                                                errors.method_payment.bol ?
                                                                "custom-select error-input" :
                                                                "custom-select br-btn-t"
                                                            }
                                                        >
                                                            <option value={undefined} default>Seleccione un tipo de pago</option>
                                                            <option value={'mercado_pago'}>Mercado pago</option>
                                                            <option value={'rapipago'}>Rapipago</option>
                                                            <option value={'transferencia'}>Deposito bancario</option>
                                                            <option value={'transferencia'}>Tarjeta de credito</option>
                                                            <option value={'transferencia'}>Transferencia bancaria</option>
                                                            <option value={'transferencia'}>Efectivo</option>
                                                        </select>
                                                    )
                                                }
                                                <div
                                                    className={"valid-input"}
                                                >
                                                    {/* {'Debe ingresar un número valido'} */}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="12">
                                            <div className={'form-group'}>
                                                <label className={'form-label fs-6'}>{'Destino/Proveedor'}</label>
                                                {
                                                    history.location.pathname.includes('view') ? (
                                                        <input
                                                            type={'text'}
                                                            name={'status_payment'}
                                                            className={"btn-disabled form-control p-form br-btn-t ml-3"}
                                                            value={vendors.results && vendors.results.filter(x => x.id === state.provider).length ?
                                                                vendors.results.filter(x => x.id === state.provider)[0].name
                                                                : null
                                                            }
                                                            readOnly
                                                        />
                                                    ) : (
                                                        <input
                                                            name={'provider'}
                                                            type={'text'}
                                                            className={errors.amount.bol ?
                                                                "form-control p-form br-btn-t error-input" :
                                                                "form-control p-form br-btn-t"
                                                            }
                                                            value={state.provider}
                                                            onChange={e => {
                                                                setState({
                                                                    ...state,
                                                                    [e.target.name]: e.target.value
                                                                })
                                                            }}
                                                        />
                                                    )
                                                }
                                                <div
                                                    className={"valid-input"}
                                                >
                                                    {/* {'Debe ingresar un número valido'} */}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" md="12">
                                            <div className="form-group">
                                                <label className="form-label fs-6">Información adicional</label>
                                                {
                                                    history.location.pathname.includes('view') ? (
                                                        <textarea
                                                            name={'details'}
                                                            className={"btn-disabled form-control p-form btn-height-6 br-btn-t resize-none ml-3"}
                                                            value={state.details}
                                                            readOnly
                                                        />
                                                    ) : (
                                                        <textarea
                                                            name={'details'}
                                                            className={"form-control p-form btn-height-6 br-btn-t resize-none"}
                                                            value={state.details}
                                                            onChange={e =>
                                                                setState({
                                                                    ...state,
                                                                    [e.target.name]: e.target.value
                                                                })
                                                            }
                                                        />
                                                    )
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="d-flex align-items-center width-50 justify-content-flex-start mb-1">
                                            {
                                                history.location.pathname.includes('view') ?
                                                    <div>
                                                        <label className="form-label fs-6">Estado</label>
                                                        <div className="d-flex align-items-center width-50 justify-content-flex-start mb-1 ml-3">
                                                            <div
                                                                style={{minWidth: '200px'}}
                                                                className="form-group d-flex align-items-center width-auto margin-bottom-0 margin-0"
                                                            >
                                                                {
                                                                    state.payment_status != "pending" ? "Pagado" : "Pendiente de pago"
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                :
                                                (state.payment_status != "pending" ) ?
                                                <>
                                                    <div
                                                        className="form-group d-flex align-items-center width-auto margin-bottom-0 margin-0"
                                                    >
                                                        <div className="custom-control-primary custom-control custom-radio">
                                                            <input id='check-6' type="radio" name="payment-status" className="custom-control-input" defaultChecked={true} />
                                                            <label
                                                                htmlFor='check-6'
                                                                className="custom-control-label checked"
                                                                onClick={() => {
                                                                    setState({ ...state, payment_status: "paid" })
                                                                }}
                                                            >
                                                                Pagado
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="form-group d-flex align-items-center margin-bottom-0 width-auto margin-0-1"
                                                    >
                                                        <div className="custom-control-primary custom-control custom-radio">
                                                            <input id='check-7' type="radio" name="payment-status" className="custom-control-input"/>
                                                            <label
                                                                htmlFor='check-7'
                                                                className="custom-control-label"
                                                                onClick={() => {
                                                                    setState({ ...state, payment_status: "pending" })
                                                                }}>
                                                                Pendiente de pago
                                                            </label>
                                                        </div>
                                                    </div>
                                                </>
                                            :
                                                <>
                                                    <div
                                                        className="form-group d-flex align-items-center width-auto margin-bottom-0 margin-0"
                                                    >
                                                        <div className="custom-control-primary custom-control custom-radio">
                                                            <input id='check-6' type="radio" name="payment-status" className="custom-control-input" />
                                                            <label
                                                                htmlFor='check-6'
                                                                className="custom-control-label checked"
                                                                onClick={() => {
                                                                    setState({ ...state, payment_status: "paid" })
                                                                }}
                                                            >
                                                                Pagado
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="form-group d-flex align-items-center margin-bottom-0 width-auto margin-0-1"
                                                    >
                                                        <div className="custom-control-primary custom-control custom-radio">
                                                            <input id='check-7' type="radio" name="payment-status" className="custom-control-input" defaultChecked={true} />
                                                            <label
                                                                htmlFor='check-7'
                                                                className="custom-control-label"
                                                                onClick={() => {
                                                                    setState({ ...state, payment_status: "pending" })
                                                                }}>
                                                                Pendiente de pago
                                                            </label>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </Row>
                                </>
                            ) : (
                                <>
                                    <Row>
                                        <Col sm="12">
                                            <div className={'form-group'}>
                                                <label className={'form-label fs-6'}>{'Gasto'}</label>
                                                {
                                                    history.location.pathname.includes('view') ? (
                                                        <input
                                                            type={'text'}
                                                            name={'concept'}
                                                            className={"btn-disabled form-control p-form br-btn-t"}
                                                            value={state.concept}
                                                            readOnly
                                                        />
                                                    ) : (
                                                        <>
                                                            <select
                                                                className="custom-select btn-height-3"
                                                                value={state.spend}
                                                                onChange={e => setSpend(e.target.value)}
                                                            >
                                                                <option value={undefined}>Seleccionar un gasto</option>
                                                                {
                                                                    expensesCommercial.length ? expensesCommercial.map(el => {
                                                                        return (
                                                                            <option value={el.id} key={el.id}>
                                                                                {el.name}
                                                                            </option>
                                                                        )
                                                                    }) : null
                                                                }
                                                            </select>
                                                            <button
                                                                type='button'
                                                                className="linkModal mt-1 color-primary background-transparent border-none"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setAddCommercialData({
                                                                        bol: true,
                                                                        title: 'Agregue el nombre del gasto que desea agregar y su monto'
                                                                    })
                                                                }}
                                                            >
                                                                + Agregar egreso
                                                            </button>
                                                        </>
                                                    )
                                                }
                                                <div
                                                    className={"valid-input"}
                                                >
                                                    {/* {'Debe ingresar un número valido'} */}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="12">
                                            <div className={'form-group'}>
                                                <label className={'form-label fs-6'}>{'Importe'}</label>
                                                {
                                                    history.location.pathname.includes('view') ? (
                                                        <>
                                                            <NumericInput
                                                                name={"amount"}
                                                                className={"btn-disabled form-control p-form br-btn-t"}
                                                                value={state.amount}
                                                                readOnly={true}
                                                            />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <NumericInput
                                                                name={"amount"}
                                                                className={errors.amount.bol ?
                                                                    "form-control p-form br-btn-t error-input" :
                                                                    "form-control p-form br-btn-t"
                                                                }
                                                                value={state.amount}
                                                                onChange={e => {
                                                                    setState({
                                                                        ...state,
                                                                        [e.target.name]: e.target.value
                                                                    })
                                                                }}
                                                            />
                                                        </>
                                                    )
                                                }
                                                <div
                                                    className={errors.amount.bol ?
                                                        "invalid-input" :
                                                        "valid-input"
                                                    }
                                                >
                                                    {errors.amount.msg}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" md="12">
                                            <div className="form-group">
                                                <label className="form-label fs-6">Información adicional</label>
                                                {
                                                    history.location.pathname.includes('view') ? (
                                                        <textarea
                                                            name={'details'}
                                                            className={"btn-disabled form-control p-form btn-height-6 br-btn-t resize-none"}
                                                            value={state.details}
                                                        />
                                                    ) : (
                                                        <textarea
                                                            name={'details'}
                                                            className={"form-control p-form btn-height-6 br-btn-t resize-none"}
                                                            value={state.details}
                                                            onChange={e =>
                                                                setState({
                                                                    ...state,
                                                                    [e.target.name]: e.target.value
                                                                })
                                                            }
                                                        />
                                                    )
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="d-flex align-items-center width-50 justify-content-flex-start mb-1">
                                            {
                                                (state.payment_status != "pending" ) ?
                                                <>
                                                    <div
                                                        className="form-group d-flex align-items-center width-auto margin-bottom-0 margin-0"
                                                    >
                                                        <div className="custom-control-primary custom-control custom-radio">
                                                            <input id='check-6' type="radio" name="payment-status" className="custom-control-input" defaultChecked={true} />
                                                            <label
                                                                htmlFor='check-6'
                                                                className="custom-control-label checked"
                                                                onClick={() => {
                                                                    setState({ ...state, payment_status: "paid" })
                                                                }}
                                                            >
                                                                Pagado
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="form-group d-flex align-items-center margin-bottom-0 width-auto margin-0-1"
                                                    >
                                                        <div className="custom-control-primary custom-control custom-radio">
                                                            <input id='check-7' type="radio" name="payment-status" className="custom-control-input"/>
                                                            <label
                                                                htmlFor='check-7'
                                                                className="custom-control-label"
                                                                onClick={() => {
                                                                    setState({ ...state, payment_status: "pending" })
                                                                }}>
                                                                Pendiente de pago
                                                            </label>
                                                        </div>
                                                    </div>
                                                </>
                                            :
                                                <>
                                                    <div
                                                        className="form-group d-flex align-items-center width-auto margin-bottom-0 margin-0"
                                                    >
                                                        <div className="custom-control-primary custom-control custom-radio">
                                                            <input id='check-6' type="radio" name="payment-status" className="custom-control-input" />
                                                            <label
                                                                htmlFor='check-6'
                                                                className="custom-control-label checked"
                                                                onClick={() => {
                                                                    setState({ ...state, payment_status: "paid" })
                                                                }}
                                                            >
                                                                Pagado
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="form-group d-flex align-items-center margin-bottom-0 width-auto margin-0-1"
                                                    >
                                                        <div className="custom-control-primary custom-control custom-radio">
                                                            <input id='check-7' type="radio" name="payment-status" className="custom-control-input" defaultChecked={true} />
                                                            <label
                                                                htmlFor='check-7'
                                                                className="custom-control-label"
                                                                onClick={() => {
                                                                    setState({ ...state, payment_status: "pending" })
                                                                }}>
                                                                Pendiente de pago
                                                            </label>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </Row>
                                </>
                            )
                        }
                    </form>
                </div>
            </div>
            <Row className="mb-3" style={window.innerWidth > 991 ? null : { display: "flex" }}>
                <div
                    className={window.innerWidth > 991 ? "col" : null}
                    style={
                        history.location.pathname.includes('view') ?
                            { display: "flex", justifyContent: "space-between" } :
                            window.innerWidth < 992 ? { width: "75%" } :
                                null
                    }
                >
                    {
                        id ? (
                            <>
                                <div>
                                    <Link to={back}>
                                        <button type="submit" className="btn btn-outline-secondary br-btn-t mr-1">Cancelar</button>
                                    </Link>
                                    <button
                                        className="btn btn-primary br-btn-t color-white"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setModal(true)
                                        }}
                                    >
                                        Borrar
                                    </button>
                                </div>
                                {history.location.pathname.includes('view') ?
                                    <Link to={`${back}/edit/${id}`}>
                                        <button type="submit" className="btn background-secondary br-btn-t color-white">Editar</button>
                                    </Link>
                                    :null
                                }
                            </>
                            
                        ) : null
                    }
                </div>
                {
                    history.location.pathname.includes('view') ?
                        null : (
                            <div
                                style={
                                    window.innerWidth > 991 ?
                                        null : { width: "25%" }
                                }
                                className={
                                    window.innerWidth > 991 ?
                                        "col d-flex justify-content-flex-end" :
                                        "d-flex justify-content-flex-end"
                                }
                            >
                                {
                                    history.location.pathname.includes('withdrawals') ? (
                                        !id ? (
                                            <Link to={back}>
                                                <button type="button" className="btn btn-outline-secondary br-btn-t mr-1">
                                                    {
                                                        history.location.pathname.includes('view') ?
                                                            'Volver' : 'Cancelar'
                                                    }
                                                </button>
                                            </Link>
                                        ) : null
                                    ) : (
                                        null
                                    )
                                }
                                {
                                    !disabled ? (
                                        <button type="button" id='save-btn' className="btn background-secondary br-btn-t color-white" onClick={e => {
                                            onSubmit(e);
                                        }}>Guardar</button>
                                    ) : (
                                        <button type="submit" className="btn background-secondary br-btn-t color-white" disabled={true}>
                                            <span className="spinner-border spinner-border-sm">
                                                <span className="sr-only"></span>
                                            </span>
                                        </button>
                                    )
                                }
                            </div>
                        )
                }
            </Row>
        </>
    )
}