import React from 'react';
import { Row, Col } from 'reactstrap';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useSelector } from 'react-redux';
import { Tooltip } from 'reactstrap';
import { NumericInput } from './NumericInput';

export default function FormNewPackaging({ state, setState, errors, history, idVendor, vendors, addVendors, help, setHelp, setAddVendors, costReplenishStock, costStockActualSupplies, id, unitsReplenishStock, back, onSubmit, disabled }) {
    const totalOfCm = state.element_height * state.element_width;

    const userIsPayment = useSelector(stee => stee?.userPremiumPay)
    
    return (
        <>
            <Row >
                {
                    state.unit_measurement === "pk" ? (
                        <>
                            <Col md={!userIsPayment?.plan?.is_free ? 3 : 6} >
                                <div className="form-group mb-1">
                                    {/* <label className="form-label fs-6">Unidad de medida</label> */}
                                    <label className="form-label fs-6">Unidad de compra</label>
                                    <HelpOutlineIcon
                                        disabled
                                        style={{ marginLeft: "1rem", fontSize: "large" }}
                                        onMouseEnter={() => {
                                            setHelp('unit_measurement')
                                        }}
                                        onMouseLeave={() => {
                                            setHelp('')
                                        }}
                                        onClick={()=> {
                                            setHelp('unit_measurement')
                                            setTimeout(() => {
                                                setHelp('')
                                            }, 1500)
                                            return
                                        }}
                                        id="unit_measurement"
                                    />
                                    <Tooltip placement="right" isOpen={help === "unit_measurement"} target="unit_measurement">
                                        Acá deberás ingresar la unidad de compra. Por ejemplo, el kilo o el metro. Si es mano de obra ingresalo por hora, y si es packaging ingresá el costo de la unidad de cada elemento. Estos últimos dos están abajo de todo.
                                    </Tooltip>
                                    {
                                        history.location.pathname.includes('view') ? (
                                            <input
                                                type={'text'}
                                                className="form-control p-form  br-btn-t btn-disabled"
                                                value={state.unit_measurement === "kg" ?
                                                    "Kilogramo" : state.unit_measurement === "m" ?
                                                        "Metros" : state.unit_measurement === "cm²" ?
                                                            "Centrimetros cuadrados" : state.unit_measurement === "l" ?
                                                                "Litros" : state.unit_measurement === "un" ?
                                                                    "Unidad" : null
                                                }
                                                readOnly
                                            />
                                        ) : (
                                            <select
                                                onChange={
                                                    (e) => setState({
                                                        ...state,
                                                        unit_measurement: e.target.value
                                                    })}
                                                value={state.unit_measurement}
                                                className={errors.unit_measurement.bol ? "custom-select  error-input" : "custom-select "}
                                            >
                                                <option default value={""}>Seleccione una unidad de medida</option>
                                                <option value="kg">Kilogramo</option>
                                                <option value="m">Metros</option>
                                                <option value="cm²">Centimetros cuadrados</option>
                                                <option value="l">Litros</option>
                                                <option value="ml">Mililitros</option>
                                                <option value="pk">Paquete</option>
                                                <option value="un">Unidad</option>
                                            </select>
                                        )
                                    }
                                    <div className={errors.unit_measurement.bol ? "invalid-input" : "valid-input"}>
                                        {errors.unit_measurement.msg}
                                    </div>
                                </div>
                            </Col>
                            <Col md={!userIsPayment?.plan?.is_free ? 3 : 6}>
                                <div className="form-group margin-bottom-0" >
                                    {/* <label className="form-label fs-6">Cantidad por paquete</label> */}
                                    <label className="form-label fs-6">Unidades por paquete</label>
                                    <div className="d-flex">
                                        {
                                            history.location.pathname.includes('supplies/view') ? (
                                                <>
                                                    <NumericInput
                                                        value={state.unit_measurement_count}
                                                        name={'unit_measurement_count'}
                                                        className={"btn-disabled form-control p-form  br-btn-t"}
                                                        readOnly={true}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <NumericInput
                                                        value={state.unit_measurement_count}
                                                        name={'unit_measurement_count'}
                                                        className={"form-control p-form  br-btn-t"}
                                                        onValueChange={e => {
                                                            setState({
                                                                ...state,
                                                                unit_measurement_count: e.value,
                                                            })
                                                        }}
                                                    />
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </Col>
                        </>
                    ) : (
                        <Col md={!userIsPayment?.plan?.is_free ? 6 : 12} >
                            <div className="form-group mb-1">
                                <label className="form-label fs-6">Unidad de medida</label>
                                <HelpOutlineIcon
                                    disabled
                                    style={{ marginLeft: "1rem", fontSize: "large" }}
                                    onMouseEnter={() => {
                                        setHelp('unit_measurement')
                                    }}
                                    onMouseLeave={() => {
                                        setHelp('')
                                    }}
                                    onClick={()=> {
                                        setHelp('unit_measurement')
                                        setTimeout(() => {
                                            setHelp('')
                                        }, 1500)
                                        return
                                    }}
                                    id="unit_measurement"
                                />
                                <Tooltip placement="right" isOpen={help === "unit_measurement"} target="unit_measurement">
                                    Acá deberás ingresar la unidad de compra. Por ejemplo, el kilo o el metro. Si es mano de obra ingresalo por hora, y si es packaging ingresá el costo de la unidad de cada elemento. Estos últimos dos están abajo de todo.
                                </Tooltip>
                                {
                                    history.location.pathname.includes('view') ? (
                                        <input
                                            type={'text'}
                                            className="form-control p-form  br-btn-t btn-disabled"
                                            value={state.unit_measurement === "kg" ?
                                                "Kilogramo" : state.unit_measurement === "m" ?
                                                    "Metros" : state.unit_measurement === "cm²" ?
                                                        "Centimetros cuadrados" : state.unit_measurement === "l" ?
                                                            "Litros" : state.unit_measurement === "un" ?
                                                                "Unidad" : null
                                            }
                                            readOnly
                                        />
                                    ) : (
                                        <select
                                            onChange={
                                                (e) => setState({
                                                    ...state,
                                                    unit_measurement: e.target.value
                                                })}
                                            value={state.unit_measurement}
                                            className={errors.unit_measurement.bol ? "custom-select  error-input" : "custom-select "}
                                        >
                                            <option default value={""}>Seleccione una unidad de medida</option>
                                            <option value="kg">Kilogramo</option>
                                            <option value="m">Metros</option>
                                            <option value="cm²">Centimetros cuadrados</option>
                                            <option value="l">Litros</option>
                                            <option value="ml">Mililitros</option>
                                            <option value="pk">Paquete</option>
                                            <option value="un">Unidad</option>
                                        </select>
                                    )
                                }
                                <div className={errors.unit_measurement.bol ? "invalid-input" : "valid-input"}>
                                    {errors.unit_measurement.msg}
                                </div>
                            </div>
                        </Col>
                    )
                }
                
                {!userIsPayment?.plan?.is_free ?
                <Col md="6">
                    <div className="form-group margin-bottom-0" >
                        <label className="form-label fs-6">Proveedor</label>
                        <HelpOutlineIcon
                            disabled
                            style={{ marginLeft: "1rem", fontSize: "large" }}
                            onMouseEnter={() => {
                                setHelp('provider')
                            }}
                            onMouseLeave={() => {
                                setHelp('')
                            }}
                            onClick={()=> {
                                setHelp('provider')
                                setTimeout(() => {
                                    setHelp('')
                                }, 1500)
                                return
                            }}
                            id="provider"
                        />
                        <Tooltip placement="right" isOpen={help === "provider"} target="provider">
                            En este campo deberás elegir un proveedor previamente cargado o agregar uno nuevo desde el button "+ Agregar proveedor"
                        </Tooltip>
                        {
                            history.location.pathname.includes('view') ? (
                                <input
                                    type={'text'}
                                    className="form-control p-form  br-btn-t btn-disabled"
                                    value={state.provider}
                                    readOnly
                                />
                            ) : (
                                <>
                                    <select
                                        onChange={(e) => setState({
                                            ...state,
                                            provider: e.target.value
                                        })}
                                        value={state.provider ? state.provider : undefined}
                                        className={errors.provider.bol ?
                                            "custom-select  error-input" :
                                            "custom-select "
                                        }
                                    >
                                        <option default value="">Seleccione un proveedor</option>
                                        {
                                            vendors.results ? vendors.results.map(el => {
                                                return (
                                                    idVendor && el.id === idVendor ? (
                                                        <option key={el.id} default value={el.id}>{el.name}</option>
                                                    ) : (
                                                        <option key={el.id} value={el.id}>{el.name}</option>
                                                    )
                                                )
                                            }) : null
                                        }
                                    </select>
                                    <div
                                        className={errors.provider.bol ?
                                            "invalid-input" :
                                            "valid-input"
                                        }
                                    >
                                        {errors.provider.msg}
                                    </div>
                                    <Row>
                                        <Col sm="12">
                                            <button
                                                type='button'
                                                className="linkModal color-primary background-transparent border-none"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setAddVendors(!addVendors)
                                                }}
                                            >
                                                + Agregar proveedor
                                            </button>
                                        </Col>
                                    </Row>
                                </>
                            )
                        }
                    </div>
                </Col>
                : null}
            </Row>
            {
                state.unit_measurement === "cm²" ? (
                    <Row >
                        <Col sm="12" md="4">
                            <div className="form-group margin-bottom-0" >
                                <label className="form-label fs-6">Largo del elemento</label>
                                <div className="d-flex">
                                    {
                                        history.location.pathname.includes('supplies/view') ? (
                                            <>
                                                <NumericInput
                                                    value={state.element_height}
                                                    name={'element_height'}
                                                    className={"btn-disabled form-control p-form  br-btn-t"}
                                                    readOnly={true}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <NumericInput
                                                    value={state.element_height}
                                                    name={'element_height'}
                                                    className={errors.element_height.bol ?
                                                        "form-control p-form  br-btn-t error-input" :
                                                        "form-control p-form  br-btn-t"
                                                    }
                                                    onValueChange={e => {
                                                        setState({
                                                            ...state,
                                                            element_height: e.value
                                                        })
                                                    }}
                                                />
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col sm="12" md="4">
                            <div className="form-group margin-bottom-0" >
                                <label className="form-label fs-6">Ancho del elemento</label>
                                <div className="d-flex">
                                    {
                                        history.location.pathname.includes('supplies/view') ? (
                                            <>
                                                <NumericInput
                                                    value={state.element_width}
                                                    name={'element_width'}
                                                    className={"btn-disabled form-control p-form  br-btn-t"}
                                                    readOnly={true}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <NumericInput
                                                    value={state.element_width}
                                                    name={'element_width'}
                                                    className={errors.element_width.bol ?
                                                        "form-control p-form  br-btn-t error-input" :
                                                        "form-control p-form  br-btn-t"
                                                    }
                                                    onValueChange={e => {
                                                        setState({
                                                            ...state,
                                                            element_width: e.value
                                                        })
                                                    }}
                                                />
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col sm="12" md="4">
                            <div className="form-group margin-bottom-0" >
                                <label className="form-label fs-6">Metros cuadrados totales</label>
                                <HelpOutlineIcon
                                    disabled
                                    style={{ marginLeft: "1rem", fontSize: "large" }}
                                    onMouseEnter={() => {
                                        setHelp('square_meter')
                                    }}
                                    onMouseLeave={() => {
                                        setHelp('')
                                    }}
                                    onClick={()=> {
                                        setHelp('square_meter')
                                        setTimeout(() => {
                                            setHelp('')
                                        }, 1500)
                                        return
                                    }}
                                    id="square_meter"
                                />
                                <Tooltip placement="right" isOpen={help === "square_meter"} target="square_meter">
                                    El sistema hará los cálculos de forma automática con los dos campos anteriores.
                                </Tooltip>
                                <div className="d-flex">
                                    {
                                        history.location.pathname.includes('supplies/view') ? (
                                            <>
                                                <NumericInput
                                                    value={totalOfCm}
                                                    name={'square_meter'}
                                                    className={"btn-disabled form-control p-form  br-btn-t"}
                                                    readOnly={true}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <NumericInput
                                                    value={totalOfCm}
                                                    name={'square_meter'}
                                                    className={errors.square_meter.bol ?
                                                        "form-control p-form  br-btn-t error-input" :
                                                        "form-control p-form  br-btn-t"
                                                    }
                                                    readOnly={true}
                                                />
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                ) : null
            }
            <Row >
                <Col md="12">
                    <Row>
                        {/* <Col sm="12" md="6" > */}
                        <Col sm="12" md={state.unit_measurement !== 'pk' ? 6 : 3}>
                            <div className="form-group">
                                <label className="form-label fs-6">Costo por unidad</label>
                                <HelpOutlineIcon
                                    disabled
                                    style={{ marginLeft: "1rem", fontSize: "large" }}
                                    onMouseEnter={() => {
                                        setHelp('square_meter')
                                    }}
                                    onMouseLeave={() => {
                                        setHelp('')
                                    }}
                                    onClick={()=> {
                                        setHelp('square_meter')
                                        setTimeout(() => {
                                            setHelp('')
                                        }, 1500)
                                        return
                                    }}
                                    id="square_meter"
                                />
                                <Tooltip placement="right" isOpen={help === "square_meter"} target="square_meter">
                                    Aca debés completar con el importe que abonás por cada unidad de compra
                                </Tooltip>
                                {
                                    history.location.pathname.includes('supplies/view') ? (
                                        <>
                                            <NumericInput
                                                value={state.cost_unit}
                                                name={'cost_unit'}
                                                className={"btn-disabled form-control p-form  br-btn-t"}
                                                readOnly={true}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <NumericInput
                                                value={state.cost_unit}
                                                name={'cost_unit'}
                                                className={errors.cost_unit.bol ?
                                                    "form-control p-form  br-btn-t error-input" :
                                                    "form-control p-form  br-btn-t"
                                                }
                                                onValueChange={e => {
                                                    setState({
                                                        ...state,
                                                        cost_unit: e.value,
                                                        stock_replenish_in_cost: costReplenishStock({
                                                            ...state,
                                                            cost_unit: e.value,
                                                        }),
                                                        current_stock_in_cost: costStockActualSupplies({
                                                            ...state,
                                                            cost_unit: e.value
                                                        })
                                                    })
                                                }}
                                            />
                                            <div className={errors.cost_unit.bol ? "invalid-input" : "valid-input"}>
                                                {errors.cost_unit.msg}
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </Col>
                        <Col sm="12" md="6" >
                            <div className="form-group">
                                <label className="form-label fs-6">Stock actual en unidades</label>
                                <HelpOutlineIcon
                                    disabled
                                    style={{ marginLeft: "1rem", fontSize: "large" }}
                                    onMouseEnter={() => {
                                        setHelp('current_stock_in_units')
                                    }}
                                    onMouseLeave={() => {
                                        setHelp('')
                                    }}
                                    onClick={()=> {
                                        setHelp('current_stock_in_units')
                                        setTimeout(() => {
                                            setHelp('')
                                        }, 1500)
                                        return
                                    }}
                                    id="current_stock_in_units"
                                />
                                <Tooltip placement="right" isOpen={help === "current_stock_in_units"} target="current_stock_in_units">
                                    La cantidad de unidades que tenés en stock actualmente
                                </Tooltip>
                                {
                                    history.location.pathname.includes('supplies/view') ? (
                                        <>
                                            <NumericInput
                                                value={state.current_stock_in_units}
                                                name={'current_stock_in_units'}
                                                className={"btn-disabled form-control p-form  br-btn-t"}
                                                decimalScale={0}
                                                readOnly={true}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <NumericInput
                                                value={state.current_stock_in_units}
                                                name={'current_stock_in_units'}
                                                className={errors.current_stock_in_units.bol ?
                                                    "form-control p-form  br-btn-t error-input" :
                                                    "form-control p-form  br-btn-t"
                                                }
                                                decimalScale={0}
                                                onValueChange={e => {
                                                    setState({
                                                        ...state,
                                                        current_stock_in_units: e.value,
                                                        stock_replenish: unitsReplenishStock({
                                                            ...state,
                                                            current_stock_in_units: e.value,
                                                        }),
                                                        stock_replenish_in_cost: costReplenishStock({
                                                            ...state,
                                                            current_stock_in_units: e.value,
                                                        }),
                                                        current_stock_in_cost: costStockActualSupplies({
                                                            ...state,
                                                            current_stock_in_units: e.value
                                                        })
                                                    })
                                                }}
                                            />
                                            <div className={errors.current_stock_in_units.bol ? "invalid-input" : "valid-input"}>
                                                {errors.current_stock_in_units.msg}
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </Col>
                        {state.unit_measurement === 'pk' ?
                        <Col sm="12" md={3}>
                            <div className="form-group">
                                <label className="form-label fs-6">Costo por unidad de uso</label>
                                <NumericInput
                                    value={state.unit_measurement === 'pk' ? (state.cost_unit / state.unit_measurement_count).toFixed(2) : (state.cost_unit / state.square_meter).toFixed(2)}
                                    name={'cost_per_unit_of_use'}
                                    className={errors.cost_per_unit_of_use?.bol ?
                                        "form-control p-form  br-btn-t error-input" :
                                        "form-control p-form  br-btn-t"
                                    }
                                    readOnly={true}
                                    onValueChange={e => {
                                        setState({
                                            ...state,
                                            cost_per_unit_of_use: e.value
                                        })
                                    }}
                                />
                                <small className="form-text text-muted" style={{fontSize: 12}}>Este valor se calcula automáticamente</small>
                                <div className={errors.cost_per_unit_of_use?.bol ? "invalid-input" : "valid-input"}>
                                    {errors.cost_per_unit_of_use?.msg}
                                </div>
                            </div>
                        </Col>
                        : null}
                    </Row>


                    <Row>
                    {!userIsPayment?.plan?.is_free ?
                        <Col sm="12" md="6" >
                            <div className="form-group">
                                <label className="form-label fs-6">Stock ideal en unidades</label>
                                <HelpOutlineIcon
                                    disabled
                                    style={{ marginLeft: "1rem", fontSize: "large" }}
                                    onMouseEnter={() => {
                                        setHelp('ideal_stock_in_units')
                                    }}
                                    onMouseLeave={() => {
                                        setHelp('')
                                    }}
                                    onClick={()=> {
                                        setHelp('ideal_stock_in_units')
                                        setTimeout(() => {
                                            setHelp('')
                                        }, 1500)
                                        return
                                    }}
                                    id="ideal_stock_in_units"
                                />
                                <Tooltip placement="right" isOpen={help === "ideal_stock_in_units"} target="ideal_stock_in_units">
                                    Esta es la cantidad de unidades que tu proyecto debería tener de cada producto
                                </Tooltip>
                                {
                                    history.location.pathname.includes('view') ? (
                                        <>
                                            <NumericInput
                                                value={state.ideal_stock_in_units}
                                                name={'ideal_stock_in_units'}
                                                className={"btn-disabled form-control p-form  br-btn-t"}
                                                decimalScale={0}
                                                readOnly={true}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <NumericInput
                                                value={state.ideal_stock_in_units}
                                                name={'ideal_stock_in_units'}
                                                className={errors.ideal_stock_in_units.bol ?
                                                    "form-control p-form  br-btn-t error-input" :
                                                    "form-control p-form  br-btn-t"
                                                }
                                                decimalScale={0}
                                                onValueChange={e => {
                                                    setState({
                                                        ...state,
                                                        ideal_stock_in_units: e.value,
                                                        stock_replenish: unitsReplenishStock({
                                                            ...state,
                                                            ideal_stock_in_units: e.value,
                                                        }),
                                                        stock_replenish_in_cost: costReplenishStock({
                                                            ...state,
                                                            ideal_stock_in_units: e.value,
                                                        })
                                                    })
                                                }}
                                            />
                                        </>
                                    )
                                }
                                <div className={errors.ideal_stock_in_units.bol ? "invalid-input" : "valid-input"}>
                                    {errors.ideal_stock_in_units.msg}
                                </div>
                            </div>
                        </Col>
                        : null}

                        {!userIsPayment?.plan?.is_free ?
                        <Col sm="12" md="6" >
                            <div className="form-group">
                                <label className="form-label fs-6">Stock actual en costo</label>
                                <HelpOutlineIcon
                                    disabled
                                    style={{ marginLeft: "1rem", fontSize: "large" }}
                                    onMouseEnter={() => {
                                        setHelp('current_stock_in_cost')
                                    }}
                                    onMouseLeave={() => {
                                        setHelp('')
                                    }}
                                    onClick={()=> {
                                        setHelp('current_stock_in_units')
                                        setTimeout(() => {
                                            setHelp('')
                                        }, 1500)
                                        return
                                    }}
                                    id="current_stock_in_cost"
                                />
                                <Tooltip placement="right" isOpen={help === "current_stock_in_cost"} target="current_stock_in_cost">
                                    Este es el valor en dinero de las unidades que tenés en stock, utilizando de base el precio de costo de tu producto.
                                </Tooltip>
                                {
                                    history.location.pathname.includes('view') ? (
                                        <>
                                            <NumericInput
                                                value={state.current_stock_in_cost}
                                                name={'current_stock_in_cost'}
                                                className={"btn-disabled form-control p-form  br-btn-t"}
                                                readOnly={true}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <NumericInput
                                                value={state.current_stock_in_cost}
                                                name={'current_stock_in_cost'}
                                                className={"form-control p-form  br-btn-t"}
                                                readOnly={true}
                                            />

                                            <small className="form-text text-muted">Este valor se calcula automáticamente</small>
                                        </>
                                    )
                                }
                            </div>
                        </Col>
                        : null}
                        
                        {!userIsPayment?.plan?.is_free ?
                        <Col sm="12" md="6" >
                            <div className="form-group">
                                <label className="form-label fs-6">Stock a reponer en unidades</label>
                                <HelpOutlineIcon
                                    disabled
                                    style={{ marginLeft: "1rem", fontSize: "large" }}
                                    onMouseEnter={() => {
                                        setHelp('stock_replenish')
                                    }}
                                    onMouseLeave={() => {
                                        setHelp('')
                                    }}
                                    id="stock_replenish"
                                />
                                <Tooltip placement="right" isOpen={help === "stock_replenish"} target="stock_replenish">
                                    Esta es la cantidad de unidades que deberías encargar para tener tu stock ideal teniendo en cuenta las unidades que tenés actualmente
                                </Tooltip>
                                {
                                    history.location.pathname.includes('view') ? (
                                        <>
                                            <NumericInput
                                                value={state.stock_replenish}
                                                name={'stock_replenish'}
                                                className={"btn-disabled form-control p-form  br-btn-t"}
                                                readOnly={true}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <NumericInput
                                                value={state.stock_replenish}
                                                name={'stock_replenish'}
                                                className={
                                                    history.location.pathname.includes('supplied/view') ?
                                                        "form-control p-form  br-btn-t btn-disabled" :
                                                        "form-control p-form  br-btn-t"
                                                }
                                                readOnly={true}
                                            />
                                            <small className="form-text text-muted">Este valor se calcula automáticamente</small>
                                        </>
                                    )
                                }
                                <div className={errors.stock_replenish.bol ? "invalid-input" : "valid-input"}>
                                    {errors.stock_replenish.msg}
                                </div>
                            </div>
                        </Col>
                        : null}
                        
                        <Col sm="12" md="6" >
                            <div className="form-group">
                                <label className="form-label fs-6">Valor de mercadería a reponer</label>
                                <HelpOutlineIcon
                                    disabled
                                    style={{ marginLeft: "1rem", fontSize: "large" }}
                                    onMouseEnter={() => {
                                        setHelp('stock_replenish_in_cost')
                                    }}
                                    onMouseLeave={() => {
                                        setHelp('')
                                    }}
                                    id="stock_replenish_in_cost"
                                />
                                <Tooltip placement="right" isOpen={help === "stock_replenish_in_cost"} target="stock_replenish_in_cost">
                                    Este es el valor en dinero de las unidades que deberías reponer, utilizando de base el precio de costo de tu producto.
                                </Tooltip>
                                {
                                    history.location.pathname.includes('view') ? (
                                    <>
                                        <NumericInput
                                            value={state.stock_replenish_in_cost}
                                            name={'stock_replenish_in_cost'}
                                            className={"btn-disabled form-control p-form  br-btn-t"}
                                            readOnly={true}
                                        />
                                    </>
                                    ) : (
                                        <>
                                            <NumericInput
                                                value={state.stock_replenish_in_cost}
                                                name={'stock_replenish_in_cost'}
                                                className={
                                                    history.location.pathname.includes('supplied/view') ?
                                                        "form-control p-form  br-btn-t btn-disabled" :
                                                        "form-control p-form  br-btn-t"
                                                }
                                                readOnly={true}
                                            />
                                            <small className="form-text text-muted">Este valor se calcula automáticamente</small>
                                        </>
                                    )
                                }
                                <div className={errors.stock_replenish_in_cost.bol ? "invalid-input" : "valid-input"}>
                                    {errors.stock_replenish_in_cost.msg}
                                </div>
                            </div>
                        </Col>
                    </Row>


                </Col>
            </Row>
        </>
    )
}